<div class="survey-main-wrapper">
	<div *ngIf="!continueSurveyAfterYearSelection && !askUserAboutChanges" class="period-selection-wrapper">
		
		<div class="survey-period-selection-descr">
			<p>
				{{'Survey.Year_selection_description_main_title' | translate}}
			</p>
			<p>{{'Survey.Year_selection_description_title' | translate}}</p>
			<p>{{'Survey.Year_selection_description' | translate}}</p>
		</div>
		
		<form *ngIf="periods" class="periodsForm" name="periodsForm" id="periodsForm" [formGroup]="periodsForm"
			  #f="ngForm" (ngSubmit)="periodsForm.valid && setPeriods(repetitionAlert)" novalidate>
			<div class="form-group">
				<div class="input-wrapper periods">
					<ng-select [items]="periods"
							   bindLabel="year"
							   #period_select
							   appendTo=".input-wrapper.periods"
							   [multiple]="false"
							   [notFoundText]="'Survey.No_such_years_are_available' | translate"
							   [placeholder]="'Survey.Select_one_or_more_needed_years' | translate"
							   [closeOnSelect]="false"
							   [hideSelected]="false"
							   [searchable]="false"
							   [clearable]="false"
							   formControlName="periods">
						<ng-template ng-option-tmp let-item="item">
							{{item.year}}
						</ng-template>
						<ng-template ng-footer-tmp>
							<button class="f-btn f-btn-main-action" (click)="closeSelect(period_select)">
								{{ 'Shared.Submit' | translate }}
							</button>
						</ng-template>
					</ng-select>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && !!periodsForm.controls.periods.errors || !!periodsForm.controls.periods.errors && (!!periodsForm.controls.periods.dirty || !!periodsForm.controls.periods.touched)">
					<p *ngIf="f.submitted && !!periodsForm.controls.periods.errors.required || !!periodsForm.controls.periods.errors.required">
						{{'Shared.Field_is_required' | translate}}
					</p>
				</div>
			</div>
			<div class="controls-wrapper period-form-controls-wrapper">
				<button class="f-btn f-btn-main-action" type="submit">
					{{ 'Survey.Start_survey_after_periods_selected' | translate }}
				</button>
			</div>
		</form>
		
		<div class="survey-period-selection-descr">
			<p>
				{{'Survey.Year_selection_attention_description1' | translate}}
				{{'Survey.Year_selection_attention_description2' | translate}}
				{{'Survey.Year_selection_attention_description3' | translate}}
			</p>
		</div>
	</div>
	
	<div *ngIf="askUserAboutChanges" class="period-changes-confirmation">
		
		<div class="question">
    <span class="title" translate [translateParams]="{year: activeYear.year}">
      {{'Survey.Has_something_changed_the_next_year'}}
    </span>
		</div>
		<div class="divider"></div>
		
		<div class="choices-wrapper">
			<div class="choice-wrapper">
				<button translate [translateParams]="{year: activeYear.year}" class="f-btn f-btn-main-action"
						(click)="authNoChangesInNextYear()">
					{{ 'Survey.Nothing_changed_the_next_year' }}
				</button>
			</div>
			
			<div class="choice-wrapper">
				<button translate [translateParams]="{year: activeYear.year}" class="f-btn f-btn-main-action"
						(click)="authContinueSurvey()">
					{{ 'Survey.Run_survey_for_next_year' }}
				</button>
			</div>
		</div>
		
		<div class="survey-useful-tips">
			<p>
				{{'Survey.Survey_useful_tips1' | translate}}
				{{'Survey.Survey_useful_tips2' | translate}}
			
			</p>
		</div>
	
	</div>
	
	<div *ngIf="continueSurveyAfterYearSelection && !askUserAboutChanges" class="survey">
		<div class="qa-wrapper multiple-years" *ngIf="QAObject">
			<div class="year-indicator en" *ngIf="lang.active === 'en'">
				{{ 'Survey.Year_indicator2' | translate }}
				<span class="year-indicator">{{activeYear.year}}</span>
			</div>
			<div class="year-indicator ru" *ngIf="lang.active === 'ru'">
				{{ 'Survey.Year_indicator2' | translate }}
				<span class="year-indicator">{{activeYear.year}}</span>
				{{ 'Survey.Year_indicator2_shortened' | translate }}
			</div>
			<div class="controls-wrapper upper">
				
				<button *ngIf="QAObject.answers.length && QAObject.id === 1"
						class="controls f-btn f-btn-main-action previous-answer" (click)="backToYearSelection()"
						[disabled]="mPeriodId">
					<i class="fas fa-arrow-left"></i>
					{{'Survey.Prev_question' | translate}}
				</button>
				
				<button *ngIf="QAObject.answers.length && QAObject.id != 1"
						class="controls f-btn f-btn-main-action previous-answer" (click)="authPreviousQuestion()"
						[disabled]="backBtnLock">
					<i class="fas fa-arrow-left"></i>
					{{'Survey.Prev_question' | translate}}
				</button>
				
				<button [disabled]="answerIsNotChosen" *ngIf="QAObject.answers.length && !QAObject.type"
						class="controls f-btn f-btn-main-action submit-answer" (click)="authAnswerQuestion()">
					{{'Survey.Next_question' | translate}}
					<i class="fas fa-arrow-right"></i>
				</button>
				
				<button
					[disabled]="QAObject.type && ((QAObject.type.type === 1 && !customAnswerValueForm.value.value) || (QAObject.type.type === 2 && !customAnswerValueForm.value.date || customAnswerValueForm.controls['date'].errors) || (QAObject.type.type === 3 && !customAnswerValueForm.value.mutipleValue))"
					*ngIf="QAObject.type" class="controls f-btn f-btn-main-action submit-answer"
					(click)="authCustomAnswerValue(QAObject.type)">
					{{'Survey.Next_question' | translate}}
					<i class="fas fa-arrow-right"></i>
				</button>
			
			</div>
			<div class="question" [ngClass]="{'qa-result': QAObject.tree_end}">
      <span class="title" *ngIf="QAObject.answers.length && !QAObject.tree_end || QAObject.tree_end">
        {{QAObject.description}}
      </span>
			
			</div>
			<div class="divider"></div>
			<div class="answers-wrapper">
				<div *ngIf="!QAObject.type">
					<div class="answer" [ngClass]="{ 'active': item.answer_chosen}"
						 *ngFor="let item of QAObject.answers" (click)="chooseAnswers(item, false)">
						<img [hidden]="item.answer_chosen" src="{{'assets/images/survayRadioUnchecked.png'}}"
							 alt="answer_unchecked">
						<img [hidden]="!item.answer_chosen" src="{{'assets/images/survayRadioChecked.png'}}"
							 alt="answer_checked">
						<span>{{item.description}}</span>
					</div>
				</div>
				<div *ngIf="QAObject.type" class="answer custom_answer" [formGroup]="customAnswerValueForm">
					<input *ngIf="QAObject.type['type'] === 1" class="form-control" name="value"
						   formControlName="value"/>
					<input *ngIf="QAObject.type['type'] === 2" type="text" name="date" id="date2"
						   class="form-control date-input" [placeholder]="" bsDatepicker
						   [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-green' }"
						   [minDate]="yearRestriction.start" [maxDate]="yearRestriction.end" formControlName="date"
						   autocomplete="off"/>
					<div *ngIf="QAObject.type['type'] === 3" class="multiple-inputs-answer">
						<label for="mutipleValue">
							<textarea class="form-control" id="mutipleValue" name="mutipleValue"
									  formControlName="mutipleValue"></textarea>
						</label>
						<label [for]="i.id" *ngFor="let i of customMultipleValues">
							<textarea class="form-control" [id]="i.id" [name]="i.name"
									  [formControlName]="i.name"></textarea>
						</label>
						<button type="button" class="additional-new-input" (click)="addMoreCustomValueInputs()">{{
							'Survey.Add_another_input' | translate }}
						</button>
					</div>
				</div>
			</div>
			<div class="controls-wrapper">
				
				<button *ngIf="QAObject.answers.length && QAObject.id === 1"
						class="controls f-btn f-btn-main-action previous-answer" (click)="backToYearSelection()"
						[disabled]="mPeriodId">
					<i class="fas fa-arrow-left"></i>
					{{'Survey.Prev_question' | translate}}
				</button>
				
				<button *ngIf="QAObject.answers.length && QAObject.id != 1"
						class="controls f-btn f-btn-main-action previous-answer" (click)="authPreviousQuestion()"
						[disabled]="backBtnLock">
					<i class="fas fa-arrow-left"></i>
					{{'Survey.Prev_question' | translate}}
				</button>
				
				<button [disabled]="answerIsNotChosen" *ngIf="QAObject.answers.length && !QAObject.type"
						class="controls f-btn f-btn-main-action submit-answer" (click)="authAnswerQuestion()">
					{{'Survey.Next_question' | translate}}
					<i class="fas fa-arrow-right"></i>
				</button>
				
				<button
					[disabled]="QAObject.type && ((QAObject.type.type === 1 && !customAnswerValueForm.value.value) || (QAObject.type.type === 2 && !customAnswerValueForm.value.date || customAnswerValueForm.controls['date'].errors) || (QAObject.type.type === 3 && !customAnswerValueForm.value.mutipleValue))"
					*ngIf="QAObject.type" class="controls f-btn f-btn-main-action submit-answer"
					(click)="authCustomAnswerValue(QAObject.type)">
					{{'Survey.Next_question' | translate}}
					<i class="fas fa-arrow-right"></i>
				</button>
			
			</div>
		</div>
		
		<div *ngIf="QAObject" [hidden]="!QAObject" class="tree-end-details-wrapper multiple-years-end">

			<span class="ending-question-title" *ngIf="!QAObject || !QAObject.answers.length && QAObject.tree_end">
				{{ 'Survey.Do_you_want_to_pass_survey_for_another_year' | translate }}
			</span>
			
			<div class="choices-wrapper">
				<div class="choice-wrapper" *ngIf="!QAObject || !QAObject.answers.length">
					<button class="f-btn f-btn-main-action" (click)="authRunSurveyForNextYear()">
						{{ 'Survey.Pass_survey_for_another_year_btn' | translate }}
					</button>
				</div>
				<div class="choice-wrapper" *ngIf="!QAObject || !QAObject.answers.length">
					<button class="f-btn f-btn-main-action" (click)="proceedToDocScan()">
						{{ 'Survey.Start_scanning_docs_btn' | translate }}
					</button>
				</div>
				<div class="choice-wrapper" *ngIf="!QAObject || !QAObject.answers.length && device">
					<button class="f-btn f-btn-main-action" (click)="exitToApp()">
						{{ 'Survey.Exit_survey' | translate }}
					</button>
				</div>
			</div>
		</div>
		
		<div *ngIf="QAObject && QAObject.id > 1" class="restart-process">
			<button class="f-btn f-btn-main-action" (click)="authRerunSurvey()">
				<i class="fas fa-undo-alt"></i>
				<span translate [translateParams]="{year: activeYear.year}">
        {{ 'Survey.Rerun_survey_btn'}}
      </span>
			</button>
		</div>
	</div>
</div>


<ng-template #repetitionAlert>
	<div class="modal-body" translate [translateParams]="{year: activeYear.year}">
		{{ 'Survey.Starting_the_same_year_exception' }}
	</div>
	<div class="modal-footer">
		<button class="modal-submit-btn no-icon-btn" (click)="repetitionAlertConfirmation()">
			<span>{{ 'Survey.Run_survey_for_the_existing_year' | translate }}</span>
		</button>
		<button class="modal-cancel-btn no-icon-btn" type="button" (click)="closeModal()">
			<span>{{ 'Shared.Close_modal' | translate }}</span>
		</button>
	</div>
</ng-template>

<ng-template #officialConfirmationModal>
	<div class="modal-body">
		<p [translateParams]="{year: activeYear.year}" translate>
			{{ 'Survey.Official_confirmation_modal_text1'}}
		</p>
		<p>{{ 'Survey.Official_confirmation_modal_text2' | translate }}</p>
	</div>
	<div class="modal-footer">
		<button class="modal-submit-btn no-icon-btn" (click)="officialConfirmation()">
			<span>{{ 'Survey.Official_confirmation_modal_confirm_btn' | translate }}</span>
		</button>
		<button class="modal-cancel-btn no-icon-btn" type="button" (click)="closeModal()">
			<span>{{ 'Survey.Official_confirmation_modal_decline_btn' | translate }}</span>
		</button>
	</div>
</ng-template>