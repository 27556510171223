<h1 class="form-title">
	{{ 'Survey.TaxID.Form.Form_title' | translate }}
</h1>

<div class="form-wrapper">
	<form
		[ngClass]="{'wrapped': !formModel.section1.active, 'ng-submitted-error': section1Form.submitted && section1Form.form.invalid}"
		#taxIdFormSection1 id="taxIdFormSection1" name="taxIdFormSection1" class="taxIdFormSection1 col-12"
		(ngSubmit)="section1Form.form.valid && submitForm(section1Form, 1, taxIdFormSection1)" #section1Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section1.active = !formModel.section1.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section1.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section1.active, 'fa-chevron-circle-down': !formModel.section1.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			<div class="form-group required_section user_name">
				<div class="input-wrapper">
					<label for="user_name">
						{{'Survey.TaxID.Form.Fields.name' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Vorname)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="user_name" id="user_name"
						   [(ngModel)]="formModel.section1.list.name.value" #user_name="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.user_name.valid">
					<p *ngIf="!section1Form.form.controls.user_name.valid">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}
					</p>
				</div>
			</div>
			<div class="form-group required_section surname">
				<div class="input-wrapper">
					<label for="surname">
						{{'Survey.TaxID.Form.Fields.surname' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Name)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="surname" id="surname"
						   [(ngModel)]="formModel.section1.list.surname.value" #surname="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.surname.valid">
					<p *ngIf="!section1Form.form.controls.surname.valid">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
				</div>
			</div>
			<div class="form-group maidenName">
				<div class="input-wrapper">
					<label for="maidenName">
						{{'Survey.TaxID.Form.Fields.maidenName' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(ggf. Geburtstagsname)</span>
					</label>
					<input class="form-control" name="maidenName" id="maidenName"
						   [(ngModel)]="formModel.section1.list.maidenName.value" #maidenName="ngModel"/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.maidenName.valid">
					<p *ngIf="!section1Form.form.controls.maidenName.valid">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
				</div>
			</div>
			<div class="form-group required_section profession">
				<div class="input-wrapper">
					<label for="profession">
						{{'Survey.TaxID.Form.Fields.profession' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Ausgeübter Beruf)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="profession" id="profession"
						   [(ngModel)]="formModel.section1.list.profession.value" #profession="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.profession.valid">
					<p *ngIf="!section1Form.form.controls.profession.valid">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
				</div>
			</div>
			<div class="form-group required_section dateOfBirth">
				<div class="input-wrapper">
					<label for="dateOfBirth">
						{{'Survey.TaxID.Form.Fields.dateOfBirth' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Geburtsdatum)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input appDateValidator class="form-control" placeholder="DD.MM.YYYY" name="dateOfBirth"
						   id="dateOfBirth" [(ngModel)]="formModel.section1.list.dateOfBirth.value"
						   #dateOfBirth="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && dateOfBirth.invalid">
					<p *ngIf="dateOfBirth.errors.required">{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
					<p *ngIf="!dateOfBirth.errors.required && dateOfBirth.errors.wrongDateFormat">
						{{'Survey.TaxID.Shared.Wrong_date_format' | translate}}</p>
					<p *ngIf="!dateOfBirth.errors.required && dateOfBirth.errors.invalidDate">
						{{'Survey.TaxID.Shared.Invalid_date' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section streetOfResidence">
				<div class="input-wrapper">
					<label for="streetOfResidence">
						{{'Survey.TaxID.Form.Fields.streetOfResidence' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Straße)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="streetOfResidence" id="streetOfResidence"
						   [(ngModel)]="formModel.section1.list.streetOfResidence.value" #streetOfResidence="ngModel"
						   required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.streetOfResidence.valid">
					<p *ngIf="!section1Form.form.controls.streetOfResidence.valid">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section houseNumber">
				<div class="input-wrapper">
					<label for="houseNumber">
						{{'Survey.TaxID.Form.Fields.houseNumber' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Hausnummer)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="houseNumber" id="houseNumber"
						   [(ngModel)]="formModel.section1.list.houseNumber.value" #houseNumber="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.houseNumber.valid">
					<p *ngIf="!section1Form.form.controls.houseNumber.valid">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
				</div>
			</div>
			<div class="form-group additionToTheAddress">
				<div class="input-wrapper">
					<label for="additionToTheAddress">
						{{'Survey.TaxID.Form.Fields.additionToTheAddress' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Adressergänzung)</span>
					</label>
					<textarea class="form-control" name="additionToTheAddress" id="additionToTheAddress"
							  [(ngModel)]="formModel.section1.list.additionToTheAddress.value"
							  #additionToTheAddress="ngModel"></textarea>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.additionToTheAddress.valid">
					<p *ngIf="!section1Form.form.controls.additionToTheAddress.valid">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section zipCode">
				<div class="input-wrapper">
					<label for="zipCode">
						{{'Survey.TaxID.Form.Fields.zipCode' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Postleitzahl)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="zipCode" id="zipCode"
						   [(ngModel)]="formModel.section1.list.zipCode.value" #zipCode="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.zipCode.valid">
					<p *ngIf="!section1Form.form.controls.zipCode.valid">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
				</div>
			</div>
			<div class="form-group required_section city">
				<div class="input-wrapper">
					<label for="city">
						{{'Survey.TaxID.Form.Fields.city' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Wohnort (Stadt))</span>
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="city" id="city" [(ngModel)]="formModel.section1.list.city.value"
						   #city="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.city.valid">
					<p *ngIf="!section1Form.form.controls.city.valid">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
				</div>
			</div>
			<div class="form-group required_section tin">
				<div class="input-wrapper">
					<label for="tin">
						{{'Survey.TaxID.Form.Fields.tin' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Identifikationsnummer)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input appNumbersValidator type="number" [min]="0" class="form-control" name="tin" id="tin"
						   [(ngModel)]="formModel.section1.list.tin.value" #tin="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && tin.invalid">
					<p *ngIf="tin.errors.required">{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
					<p *ngIf="tin.errors.onlyNumberAvailable">
						{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
					</p>
					<p *ngIf="!tin.errors.required && tin.errors.minValueError">
						{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
					</p>
				</div>
			</div>
			<div class="form-group required_section religiousAffiliationToAParticularChurch">
				<div class="input-wrapper">
					<label for="religiousAffiliationToAParticularChurch">
						{{'Survey.TaxID.Form.Fields.religiousAffiliationToAParticularChurch' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Religion)</span>
						<span class="required_asterisk">*</span>
						<span class="tooltip-wrapper">
                    <i class="fas fa-info-circle" [tooltip]="tooltipReligionTemplate" container="body"
					   placement="right"></i>
                  </span>
					</label>
					<input maxlength="2" class="form-control" name="religiousAffiliationToAParticularChurch"
						   id="religiousAffiliationToAParticularChurch"
						   [(ngModel)]="formModel.section1.list.religiousAffiliationToAParticularChurch.value"
						   #religiousAffiliationToAParticularChurch="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && religiousAffiliationToAParticularChurch.invalid">
					<p *ngIf="religiousAffiliationToAParticularChurch.errors.required">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}
					</p>
					<p *ngIf="religiousAffiliationToAParticularChurch.errors.maxlength">
						{{'Survey.TaxID.Shared.Field_is_too_long' | translate}}
					</p>
				</div>
			</div>
			<div class="form-group required_section phoneNumber">
				<div class="input-wrapper">
					<label for="phoneNumber">
						{{'Survey.TaxID.Form.Fields.phoneNumber' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Telefon)</span>
						<span class="required_asterisk">*</span>
					</label>
					<international-phone-number name="phoneNumber" id="phoneNumber" placeholder=""
												(ngModelChange)="onchangeValidation(section1Form)"
												[defaultCountry]="'de'"
												[(ngModel)]="formModel.section1.list.phoneNumber.value"
												[required]="true" #phoneNumber="ngModel"
												[ngClass]="{ 'submitted' : section1Form.submitted}"></international-phone-number>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted">
					<p *ngIf="!section1Form.form.controls.phoneNumber.valid">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
					<p *ngIf="section1Form.form.controls.phoneNumber.errors && section1Form.form.controls.phoneNumber.errors['wrong_number']">
						{{'Survey.TaxID.Shared.Phone_number_is_not_valid' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section email">
				<div class="input-wrapper email">
					<label for="email">
						{{'Survey.TaxID.Form.Fields.email' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(E-Mail)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input appEmailValidator type="email" placeholder="example@domain.com" class="form-control"
						   name="email" id="email" [(ngModel)]="formModel.section1.list.email.value" #email="ngModel"
						   required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && email.invalid">
					<p *ngIf="email.errors.required">{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
					<p *ngIf="!email.errors.required && email.errors.wrongEmailFormat">
						{{'Survey.TaxID.Shared.Email_is_not_valid' | translate}}</p>
				</div>
			</div>
			<div class="form-group professionDetails">
				<div class="input-wrapper">
					<label for="professionDetails">
						{{'Survey.TaxID.Form.Fields.professionDetails' | translate}}
						<span *ngIf="lang.active !== 'de'"
							  class="de-translation">(Genaue bezeichnung der Tätigkeit)</span>
					</label>
					<textarea class="form-control" name="professionDetails" id="professionDetails"
							  [(ngModel)]="formModel.section1.list.professionDetails.value"
							  #professionDetails="ngModel"></textarea>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section1Form.submitted && !section1Form.form.controls.professionDetails.valid">
					<p *ngIf="!section1Form.form.controls.professionDetails.valid">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			
			<div style="display: none;" class="taxAdvice-wrapper conditional-subsection">
				<div class="conditional-subsection-subtitle active">
					<div class="form-group taxAdviceActive">
						<div class="input-wrapper">
							<input (change)="toggleQ('section1', 'taxAdvice')" type="checkbox"
								   class="form-control checkbox" name="taxAdviceActive" id="taxAdviceActive"
								   [(ngModel)]="formModel.section1.list.taxAdvice.value" #taxAdviceActive="ngModel"/>
							<label for="taxAdviceActive" class="checkbox-label">
								{{'Survey.TaxID.Form.TaxAdvice' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Steuerliche Beratung)</span>
							</label>
						</div>
					</div>
				</div>
				<div class="form-group required_section taxAdviceFirm">
					<div class="input-wrapper">
						<label for="taxAdviceFirm">
							{{'Form.Fields.taxAdviceFirm' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Firma)</span>
							<span
								*ngIf="!formModel.section1.list.taxAdviceName.value || !formModel.section1.list.taxAdviceSurname.value"
								class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" class="form-control" name="taxAdviceFirm"
							   id="taxAdviceFirm" [(ngModel)]="formModel.section1.list.taxAdviceFirm.value"
							   #taxAdviceFirm="ngModel"
							   [required]="!formModel.section1.list.taxAdviceName.value || !formModel.section1.list.taxAdviceSurname.value"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceFirm.invalid">
						<p *ngIf="taxAdviceFirm.errors.required">{{'Shared.Field_is_required' | translate}}</p>
					</div>
				</div>
				<div class="additional-label">
					{{ 'Survey.TaxID.Form.Fields2.or' | translate }}
				</div>
				<div class="form-group required_section taxAdviceSurname">
					<div class="input-wrapper">
						<label for="taxAdviceSurname">
							{{'Survey.TaxID.Form.Fields.taxAdviceSurname' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Name)</span>
							<span *ngIf="!formModel.section1.list.taxAdviceFirm.value"
								  class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" class="form-control" name="taxAdviceSurname"
							   id="taxAdviceSurname" [(ngModel)]="formModel.section1.list.taxAdviceSurname.value"
							   #taxAdviceSurname="ngModel" [required]="!formModel.section1.list.taxAdviceFirm.value"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceSurname.invalid">
						<p *ngIf="taxAdviceSurname.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceName">
					<div class="input-wrapper">
						<label for="taxAdviceName">
							{{'Survey.TaxID.Form.Fields.taxAdviceName' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Vorname)</span>
							<span *ngIf="!formModel.section1.list.taxAdviceFirm.value"
								  class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" class="form-control" name="taxAdviceName"
							   id="taxAdviceName" [(ngModel)]="formModel.section1.list.taxAdviceName.value"
							   #taxAdviceName="ngModel" [required]="!formModel.section1.list.taxAdviceFirm.value"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceName.invalid">
						<p *ngIf="taxAdviceName.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceStreet">
					<div class="input-wrapper">
						<label for="taxAdviceStreet">
							{{'Survey.TaxID.Form.Fields.taxAdviceStreet' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Straße)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" class="form-control" name="taxAdviceStreet"
							   id="taxAdviceStreet" [(ngModel)]="formModel.section1.list.taxAdviceStreet.value"
							   #taxAdviceStreet="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceStreet.invalid">
						<p *ngIf="taxAdviceStreet.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceHouseNumber">
					<div class="input-wrapper">
						<label for="taxAdviceHouseNumber">
							{{'Survey.TaxID.Form.Fields.taxAdviceHouseNumber' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Hausnummer)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" class="form-control" name="taxAdviceHouseNumber"
							   id="taxAdviceHouseNumber"
							   [(ngModel)]="formModel.section1.list.taxAdviceHouseNumber.value"
							   #taxAdviceHouseNumber="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceHouseNumber.invalid">
						<p *ngIf="taxAdviceHouseNumber.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceZipCode">
					<div class="input-wrapper">
						<label for="taxAdviceZipCode">
							{{'Survey.TaxID.Form.Fields.taxAdviceZipCode' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Postleitzahl)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" class="form-control" name="taxAdviceZipCode"
							   id="taxAdviceZipCode" [(ngModel)]="formModel.section1.list.taxAdviceZipCode.value"
							   #taxAdviceZipCode="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceZipCode.invalid">
						<p *ngIf="taxAdviceZipCode.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceCity">
					<div class="input-wrapper">
						<label for="taxAdviceCity">
							{{'Survey.TaxID.Form.Fields.taxAdviceCity' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Ort)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" class="form-control" name="taxAdviceCity"
							   id="taxAdviceCity" [(ngModel)]="formModel.section1.list.taxAdviceCity.value"
							   #taxAdviceCity="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceCity.invalid">
						<p *ngIf="taxAdviceCity.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceIntCode">
					<div class="input-wrapper">
						<label for="taxAdviceIntCode">
							{{'Survey.TaxID.Form.Fields.taxAdviceIntCode' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Vorwahl international)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" appNumbersValidator type="number" [min]="0"
							   class="form-control" name="taxAdviceIntCode" id="taxAdviceIntCode"
							   [(ngModel)]="formModel.section1.list.taxAdviceIntCode.value" #taxAdviceIntCode="ngModel"
							   required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceIntCode.invalid">
						<p *ngIf="taxAdviceIntCode.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="taxAdviceIntCode.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!taxAdviceIntCode.errors.required && taxAdviceIntCode.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceNationalCode">
					<div class="input-wrapper">
						<label for="taxAdviceNationalCode">
							{{'Survey.TaxID.Form.Fields.taxAdviceNationalCode' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Vorwahl national)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" appNumbersValidator type="number" [min]="0"
							   class="form-control" name="taxAdviceNationalCode" id="taxAdviceNationalCode"
							   [(ngModel)]="formModel.section1.list.taxAdviceNationalCode.value"
							   #taxAdviceNationalCode="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceNationalCode.invalid">
						<p *ngIf="taxAdviceNationalCode.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="taxAdviceNationalCode.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!taxAdviceNationalCode.errors.required && taxAdviceNationalCode.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				<div class="form-group required_section taxAdvicePhoneNumber">
					<div class="input-wrapper">
						<label for="taxAdvicePhoneNumber">
							{{'Survey.TaxID.Form.Fields.taxAdvicePhoneNumber' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Rufnummer)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" appNumbersValidator type="number" [min]="0"
							   class="form-control" name="taxAdvicePhoneNumber" id="taxAdvicePhoneNumber"
							   [(ngModel)]="formModel.section1.list.taxAdvicePhoneNumber.value"
							   #taxAdvicePhoneNumber="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdvicePhoneNumber.invalid">
						<p *ngIf="taxAdvicePhoneNumber.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="taxAdvicePhoneNumber.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!taxAdvicePhoneNumber.errors.required && taxAdvicePhoneNumber.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				<div class="form-group required_section taxAdviceEmail">
					<div class="input-wrapper">
						<label for="taxAdviceEmail">
							{{'Survey.TaxID.Form.Fields.taxAdviceEmail' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(E-Mail)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input (change)="taxAdviceChangeEvent($event)" appEmailValidator type="email"
							   placeholder="example@domain.com" class="form-control" name="taxAdviceEmail"
							   id="taxAdviceEmail" [(ngModel)]="formModel.section1.list.taxAdviceEmail.value"
							   #taxAdviceEmail="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && taxAdviceEmail.invalid">
						<p *ngIf="taxAdviceEmail.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="!taxAdviceEmail.errors.required && taxAdviceEmail.errors.wrongEmailFormat">
							{{'Survey.TaxID.Shared.Email_is_not_valid' | translate}}</p>
					</div>
				</div>
			</div>
			
			<div class="previousPersonalDetails-wrapper conditional-subsection">
				<div class="conditional-subsection-title">
					{{'Survey.TaxID.Form.PreviousPersonalDetails' | translate}}
					<span *ngIf="lang.active !== 'de'"
						  class="de-translation">(Bisherige persönliche Verhältnisse)</span>
				</div>
				<div class="conditional-subsection-subtitle"
					 [class.active]="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress">
					<div class="form-group movedToCurrentAddress">
						<div class="input-wrapper">
							<input (change)="toggleQ('section1', 'movedToCurrentAddress')" type="checkbox"
								   class="form-control checkbox" name="movedToCurrentAddress" id="movedToCurrentAddress"
								   [(ngModel)]="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
								   #movedToCurrentAddress="ngModel"/>
							<label for="movedToCurrentAddress" class="checkbox-label">
								{{'Survey.TaxID.Form.YouMovedToTheCurrentPlace' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Falls Sie in den letzten 12 Monaten zugezogen sind)</span>
							</label>
						</div>
					</div>
				</div>
				
				<div *ngIf="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
					 class="form-group required_section dateOfTheMove">
					<div class="input-wrapper dateOfTheMove">
						<label for="dateOfTheMove">
							{{'Survey.TaxID.Form.Fields.dateOfTheMove' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Datum eingeben)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appDateValidator placeholder="DD.MM.YYYY" class="form-control" name="dateOfTheMove"
							   id="dateOfTheMove" [(ngModel)]="formModel.section1.list.dateOfTheMove.value"
							   #dateOfTheMove="ngModel"
							   [required]="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && dateOfTheMove.invalid">
						<p *ngIf="dateOfTheMove.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="!dateOfTheMove.errors.required && dateOfTheMove.errors.wrongDateFormat">
							{{'Survey.TaxID.Shared.Wrong_date_format' | translate}}</p>
						<p *ngIf="!dateOfTheMove.errors.required && dateOfTheMove.errors.invalidDate">
							{{'Survey.TaxID.Shared.Invalid_date' | translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
					 class="form-group previousTax">
					<div class="input-wrapper previousTax">
						<label for="previousTax">
							{{'Survey.TaxID.Form.Fields.previousTax' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Bisheriges Finanzamt)</span>
						</label>
						<input class="form-control" name="previousTax" id="previousTax"
							   [(ngModel)]="formModel.section1.list.previousTax.value" #previousTax="ngModel"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && previousTax.invalid">
						<p *ngIf="previousTax.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
					 class="form-group previousTaxNumber">
					<div class="input-wrapper previousTaxNumber">
						<label for="previousTaxNumber">
							{{'Survey.TaxID.Form.Fields.previousTaxNumber' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Vorhandene Steuernummer)</span>
						</label>
						<input appNumbersValidator type="number" class="form-control" name="previousTaxNumber"
							   id="previousTaxNumber" [(ngModel)]="formModel.section1.list.previousTaxNumber.value"
							   #previousTaxNumber="ngModel"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && previousTaxNumber.invalid">
						<p *ngIf="previousTaxNumber.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="previousTaxNumber.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!previousTaxNumber.errors.required && previousTaxNumber.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				
				<div *ngIf="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
					 class="form-group required_section previousCity">
					<div class="input-wrapper previousCity">
						<label for="previousCity">
							{{'Survey.TaxID.Form.Fields.previousCity' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Wohnort (Stadt))</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousCity" id="previousCity"
							   [(ngModel)]="formModel.section1.list.previousCity.value" #previousCity="ngModel"
							   [required]="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && previousCity.invalid">
						<p *ngIf="previousCity.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
					 class="form-group required_section previousStreet">
					<div class="input-wrapper previousStreet">
						<label for="previousStreet">
							{{'Survey.TaxID.Form.Fields.previousStreet' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Straße)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousStreet" id="previousStreet"
							   [(ngModel)]="formModel.section1.list.previousStreet.value" #previousStreet="ngModel"
							   [required]="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && previousStreet.invalid">
						<p *ngIf="previousStreet.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
					 class="form-group required_section previousStreetNumber">
					<div class="input-wrapper previousStreetNumber">
						<label for="previousStreetNumber">
							{{'Survey.TaxID.Form.Fields.previousStreetNumber' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Hausnummer)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousStreetNumber" id="previousStreetNumber"
							   [(ngModel)]="formModel.section1.list.previousStreetNumber.value"
							   #previousStreetNumber="ngModel"
							   [required]="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && previousStreetNumber.invalid">
						<p *ngIf="previousStreetNumber.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"
					 class="form-group required_section previousZipCode">
					<div class="input-wrapper previousZipCode">
						<label for="previousZipCode">
							{{'Survey.TaxID.Form.Fields.previousZipCode' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Postleitzahl)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousZipCode" id="previousZipCode"
							   [(ngModel)]="formModel.section1.list.previousZipCode.value" #previousZipCode="ngModel"
							   [required]="formModel.section1.conditionalSectionsToggle.movedToCurrentAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section1Form.submitted && previousZipCode.invalid">
						<p *ngIf="previousZipCode.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
			</div>
			
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection1)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		</div>
	
	</form>
	
	<form
		[ngClass]="{'wrapped': !formModel.section2.active, 'ng-submitted-error': section2Form.submitted && section2Form.form.invalid}"
		#taxIdFormSection2 id="taxIdFormSection2" name="taxIdFormSection2" class="taxIdFormSection2 col-12"
		(ngSubmit)="section2Form.form.valid && submitForm(section2Form, 2, taxIdFormSection2)" #section2Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section2.active = !formModel.section2.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section2.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section2.active, 'fa-chevron-circle-down': !formModel.section2.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			
			<div class="form-group required_section enterpriseName">
				<div class="input-wrapper">
					<label for="enterpriseName">
						{{'Survey.TaxID.Form.Fields2.enterpriseName' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Назва підприємства)</span>
						<span class="tooltip-wrapper">
                    <i class="fas fa-info-circle"
					   tooltip="{{'Survey.TaxID.Form.Fields2.enterpriseNameTooltipText' | translate}}" container="body"
					   placement="right"></i>
                  </span>
						<!--<span class="required_asterisk">*</span>-->
					</label>
					<input class="form-control" name="enterpriseName" id="enterpriseName"
						   [(ngModel)]="formModel.section2.list.enterpriseName.value" #enterpriseName="ngModel"/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section2Form.submitted && !section2Form.form.controls.enterpriseName.valid">
					<p *ngIf="!section2Form.form.controls.enterpriseName.valid">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			
			<div class="movedToCurrentAddress-wrapper conditional-subsection">
				<div class="conditional-subsection-subtitle"
					 [class.active]="formModel.section2.conditionalSectionsToggle.enterpriseAddress">
					<div class="form-group enterpriseAddressActive">
						<div class="input-wrapper">
							<input (change)="toggleQ('section2', 'enterpriseAddress')" type="checkbox"
								   class="form-control checkbox" name="enterpriseAddressActive"
								   id="enterpriseAddressActive"
								   [(ngModel)]="formModel.section2.conditionalSectionsToggle.enterpriseAddress"
								   #enterpriseAddressActive="ngModel"/>
							<label for="enterpriseAddressActive" class="checkbox-label">
								{{'Survey.TaxID.Form.EnterpriseAddress' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Anschrift des Unternehmens (wenn gleich wie oben, übernehmen))</span>
								<span class="tooltip-wrapper">
                        <i class="fas fa-info-circle"
						   tooltip="{{'Survey.TaxID.Form.Fields2.enterpriseAddressTooltipText' | translate}}"
						   container="body" placement="right"></i>
                      </span>
							</label>
						</div>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.enterpriseAddress"
					 class="form-group required_section enterpriseAddress">
					<div class="input-wrapper">
						<label for="enterpriseAddress">
							{{'Survey.TaxID.Form.Fields2.enterpriseAddress' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Straße)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="enterpriseAddress" id="enterpriseAddress"
							   [(ngModel)]="formModel.section2.list.enterpriseAddress.value"
							   #enterpriseAddress="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.enterpriseAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && enterpriseAddress.invalid">
						<p *ngIf="enterpriseAddress.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.enterpriseAddress"
					 class="form-group required_section enterpriseHouseNumber">
					<div class="input-wrapper">
						<label for="enterpriseHouseNumber">
							{{'Survey.TaxID.Form.Fields2.enterpriseHouseNumber' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Hausnummer)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="enterpriseHouseNumber" id="enterpriseHouseNumber"
							   [(ngModel)]="formModel.section2.list.enterpriseHouseNumber.value"
							   #enterpriseHouseNumber="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.enterpriseAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && enterpriseHouseNumber.invalid">
						<p *ngIf="enterpriseHouseNumber.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.enterpriseAddress"
					 class="form-group enterpriseAddressAdditions">
					<div class="input-wrapper">
						<label for="enterpriseAddressAdditions">
							{{'Survey.TaxID.Form.Fields2.enterpriseAddressAdditions' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Adressergänzung)</span>
						</label>
						<textarea class="form-control" name="enterpriseAddressAdditions" id="enterpriseAddressAdditions"
								  [(ngModel)]="formModel.section2.list.enterpriseAddressAdditions.value"
								  #enterpriseAddressAdditions="ngModel"></textarea>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && enterpriseAddressAdditions.invalid">
						<p *ngIf="enterpriseAddressAdditions.errors.required">{{'Survey.TaxID.Shared.Field_is_required'
							| translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.enterpriseAddress"
					 class="form-group required_section enterpriseZipcode">
					<div class="input-wrapper">
						<label for="enterpriseZipcode">
							{{'Survey.TaxID.Form.Fields2.enterpriseZipcode' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Postleitzahl)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="enterpriseZipcode" id="enterpriseZipcode"
							   [(ngModel)]="formModel.section2.list.enterpriseZipcode.value"
							   #enterpriseZipcode="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.enterpriseAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && enterpriseZipcode.invalid">
						<p *ngIf="enterpriseZipcode.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.enterpriseAddress"
					 class="form-group required_section enterpriseCity">
					<div class="input-wrapper">
						<label for="enterpriseCity">
							{{'Survey.TaxID.Form.Fields2.enterpriseCity' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Wohnort (Stadt))</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="enterpriseCity" id="enterpriseCity"
							   [(ngModel)]="formModel.section2.list.enterpriseCity.value" #enterpriseCity="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.enterpriseAddress"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && enterpriseCity.invalid">
						<p *ngIf="enterpriseCity.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
			</div>
			
			<div class="form-group required_section startOfPlannedActivities">
				<div class="input-wrapper">
					<label for="startOfPlannedActivities">
						{{'Survey.TaxID.Form.StartOfPlannedActivities' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Beginn der Tätigkeit)</span>
						<span class="required_asterisk">*</span>
					</label>
					<input appDateValidator class="form-control" placeholder="DD.MM.YYYY"
						   name="startOfPlannedActivities" id="startOfPlannedActivities"
						   [(ngModel)]="formModel.section2.list.startOfPlannedActivities.value"
						   #startOfPlannedActivities="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section2Form.submitted && startOfPlannedActivities.invalid">
					<p *ngIf="startOfPlannedActivities.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
						translate}}</p>
					<p *ngIf="!startOfPlannedActivities.errors.required && startOfPlannedActivities.errors.wrongDateFormat">
						{{'Survey.TaxID.Shared.Wrong_date_format' | translate}}</p>
					<p *ngIf="!startOfPlannedActivities.errors.required && startOfPlannedActivities.errors.invalidDate">
						{{'Survey.TaxID.Shared.Invalid_date' | translate}}</p>
				</div>
			</div>
			
			<div class="differentBranches-wrapper conditional-subsection">
				<div class="conditional-subsection-subtitle"
					 [class.active]="formModel.section2.conditionalSectionsToggle.differentBranches">
					<div class="form-group differentBranchesActive">
						<div class="input-wrapper">
							<input (change)="toggleQ('section2', 'differentBranches')" type="checkbox"
								   class="form-control checkbox" name="differentBranchesActive"
								   id="differentBranchesActive"
								   [(ngModel)]="formModel.section2.conditionalSectionsToggle.differentBranches"
								   #differentBranchesActive="ngModel"/>
							<label for="differentBranchesActive" class="checkbox-label">
								{{'Survey.TaxID.Form.PlanningToHaveDifferentBranchesInCities' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Wenn Sie Büros in verschiedenen Städten oder Bezirken planen, geben Sie bitte die Adresse der einzelnen Abteilungen an)</span>
							</label>
						</div>
					</div>
				</div>
				
				<div [class.active]="formModel.section2.conditionalSectionsToggle.differentBranches"
					 class="branchArrayItemWrapper"
					 *ngFor="let i of formModel.section2.list.differentBranches.itemsList; index as index">
					<div class="form-group required_section differentBranchesStreet"
						 *ngIf="formModel.section2.conditionalSectionsToggle.differentBranches">
						<div class="input-wrapper">
							<label [for]="'differentBranchesStreet' + index">
								{{'Survey.TaxID.Form.Fields2.differentBranchesStreet' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Straße)</span>
								<span class="required_asterisk">*</span>
							</label>
							<input class="form-control" [name]="'differentBranchesStreet' + index"
								   [id]="'differentBranchesStreet' + index" [(ngModel)]="i.street"
								   #differentBranchesStreet="ngModel"
								   [required]="formModel.section2.conditionalSectionsToggle.differentBranches"/>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section2Form.submitted && differentBranchesStreet.invalid">
							<p *ngIf="differentBranchesStreet.errors.required">{{'Survey.TaxID.Shared.Field_is_required'
								| translate}}</p>
						</div>
					</div>
					<div class="form-group required_section differentBranchesHouseNumber"
						 *ngIf="formModel.section2.conditionalSectionsToggle.differentBranches">
						<div class="input-wrapper">
							<label [for]="'differentBranchesHouseNumber' + index">
								{{'Survey.TaxID.Form.Fields2.differentBranchesHouseNumber' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Hausnummer)</span>
								<span class="required_asterisk">*</span>
							</label>
							<input class="form-control" [name]="'differentBranchesHouseNumber' + index"
								   [id]="'differentBranchesHouseNumber' + index" [(ngModel)]="i.houseNumber"
								   #differentBranchesHouseNumber="ngModel"
								   [required]="formModel.section2.conditionalSectionsToggle.differentBranches"/>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section2Form.submitted && differentBranchesHouseNumber.invalid">
							<p *ngIf="differentBranchesHouseNumber.errors.required">
								{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
						</div>
					</div>
					<div class="form-group required_section differentBranchesAddressAdditions"
						 *ngIf="formModel.section2.conditionalSectionsToggle.differentBranches">
						<div class="input-wrapper">
							<label [for]="'differentBranchesAddressAdditions' + index">
								{{'Survey.TaxID.Form.Fields2.differentBranchesAddressAdditions' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Adressergänzung)</span>
							</label>
							<textarea class="form-control" [name]="'differentBranchesAddressAdditions' + index"
									  [id]="'differentBranchesAddressAdditions' + index"
									  [(ngModel)]="i.addressAdditions"
									  #differentBranchesAddressAdditions="ngModel"></textarea>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section2Form.submitted && differentBranchesAddressAdditions.invalid">
							<p *ngIf="differentBranchesAddressAdditions.errors.required">
								{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
						</div>
					</div>
					<div class="form-group required_section differentBranchesZipcode"
						 *ngIf="formModel.section2.conditionalSectionsToggle.differentBranches">
						<div class="input-wrapper">
							<label [for]="'differentBranchesZipcode' + index">
								{{'Survey.TaxID.Form.Fields2.differentBranchesZipcode' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Postleitzahl)</span>
								<span class="required_asterisk">*</span>
							</label>
							<input class="form-control" [name]="'differentBranchesZipcode' + index"
								   [id]="'differentBranchesZipcode' + index" [(ngModel)]="i.zipCode"
								   #differentBranchesZipcode="ngModel"
								   [required]="formModel.section2.conditionalSectionsToggle.differentBranches"/>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section2Form.submitted && differentBranchesZipcode.invalid">
							<p *ngIf="differentBranchesZipcode.errors.required">
								{{'Survey.TaxID.Shared.Field_is_required' | translate}}</p>
						</div>
					</div>
					<div class="form-group required_section differentBranchesCity"
						 *ngIf="formModel.section2.conditionalSectionsToggle.differentBranches">
						<div class="input-wrapper">
							<label [for]="'differentBranchesCity' + index">
								{{'Survey.TaxID.Form.Fields2.differentBranchesCity' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Wohnort (Stadt))</span>
								<span class="required_asterisk">*</span>
							</label>
							<input class="form-control" [name]="'differentBranchesCity' + index"
								   [id]="'differentBranchesCity' + index" [(ngModel)]="i.city"
								   #differentBranchesCity="ngModel"
								   [required]="formModel.section2.conditionalSectionsToggle.differentBranches"/>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section2Form.submitted && differentBranchesCity.invalid">
							<p *ngIf="differentBranchesCity.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
								translate}}</p>
						</div>
					</div>
				</div>
				
				<div class="add-more-addresses" *ngIf="formModel.section2.conditionalSectionsToggle.differentBranches">
					<button class="add-more" type="button" (click)="addBranchAddresses()">
						{{ 'Survey.TaxID.Form.AddMoreBranchAddressesBtn' | translate}}
					</button>
					<button class="delete" *ngIf="formModel.section2.list.differentBranches.itemsList.length > 1"
							type="button" (click)="deleteBranchAddresses()">
						{{ 'Survey.TaxID.Form.DeleteBranchAddressesBtn' | translate}}
					</button>
				</div>
			</div>
			
			<div class="foundationFormOfTheEnterprise-wrapper radiobutton-section-wrapper"
				 [class.active]="formModel.section2.list.foundationFormOfTheEnterprise.value">
				<div class="radiobutton-uni-lable">
					{{'Survey.TaxID.Form.FoundationFormOfTheEnterprise' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Gründungsform)</span>
					<span class="required_asterisk">*</span>
				</div>
				<div class="form-group required_section radiobutton-section willSellGoodsOrServicesWithAverageRates">
					<div class="input-wrapper"
						 *ngFor="let i of formModel.section2.list.foundationFormOfTheEnterprise.itemsList">
						<input type="radio" class="form-control radiobutton" name="{{i.id}}" id="{{i.id}}"
							   [(ngModel)]="formModel.section2.list.foundationFormOfTheEnterprise.value"
							   value="{{i.id}}" #foundationFormOfTheEnterprise="ngModel" required/>
						<label for="{{i.id}}" class="radiobutton-label">
							{{i.name | translate}}
						</label>
					</div>
				</div>
			</div>
			
			<div class="previousFirm-wrapper conditional-subsection">
				<div class="conditional-subsection-subtitle"
					 [class.active]="formModel.section2.conditionalSectionsToggle.previousFirm">
					<div class="form-group previousFirmActive">
						<div class="input-wrapper">
							<input (change)="toggleQ('section2', 'previousFirm')" type="checkbox"
								   class="form-control checkbox" name="previousFirmActive" id="previousFirmActive"
								   [(ngModel)]="formModel.section2.conditionalSectionsToggle.previousFirm"
								   #previousFirmActive="ngModel"/>
							<label for="previousFirmActive" class="checkbox-label">
								{{'Survey.TaxID.Form.PreviousFirm' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Vorherige Firma)</span>
							</label>
						</div>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousFirm"
					 class="form-group required_section previousFirmName">
					<div class="input-wrapper">
						<label for="previousFirmName">
							{{'Survey.TaxID.Form.Fields2.previousFirmName' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Der Name der vorherigen Firma)</span>
							<span
								*ngIf="!formModel.section2.list.previousFirmOwnerName.value || !formModel.section2.list.previousFirmOwnerSurname.value"
								class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousFirmName" id="previousFirmName"
							   [(ngModel)]="formModel.section2.list.previousFirmName.value" #previousFirmName="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousFirm && (!formModel.section2.list.previousFirmOwnerName.value || !formModel.section2.list.previousFirmOwnerSurname.value)"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousFirmName.invalid">
						<p *ngIf="previousFirmName.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousFirm" class="additional-label">
					{{ 'Survey.TaxID.Form.Fields2.or' | translate }}
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousFirm"
					 class="form-group required_section previousFirmOwnerName">
					<div class="input-wrapper">
						<label for="previousFirmOwnerName">
							{{'Survey.TaxID.Form.Fields2.previousFirmOwnerName' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Vorheriger Besitzer: Vorname)</span>
							<span *ngIf="!formModel.section2.list.previousFirmName.value"
								  class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousFirmOwnerName" id="previousFirmOwnerName"
							   [(ngModel)]="formModel.section2.list.previousFirmOwnerName.value"
							   #previousFirmOwnerName="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousFirm && !formModel.section2.list.previousFirmName.value"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousFirmOwnerName.invalid">
						<p *ngIf="previousFirmOwnerName.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousFirm"
					 class="form-group required_section previousFirmOwnerSurname">
					<div class="input-wrapper">
						<label for="previousFirmOwnerSurname">
							{{'Survey.TaxID.Form.Fields2.previousFirmOwnerSurname' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Vorheriger Besitzer: Name)</span>
							<span *ngIf="!formModel.section2.list.previousFirmName.value"
								  class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousFirmOwnerSurname" id="previousFirmOwnerSurname"
							   [(ngModel)]="formModel.section2.list.previousFirmOwnerSurname.value"
							   #previousFirmOwnerSurname="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousFirm && !formModel.section2.list.previousFirmName.value"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousFirmOwnerSurname.invalid">
						<p *ngIf="previousFirmOwnerSurname.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousFirm"
					 class="form-group required_section previousFirmAddress">
					<div class="input-wrapper">
						<label for="previousFirmAddress">
							{{'Survey.TaxID.Form.Fields2.previousFirmAddress' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Die Adresse der vorherigen Firma)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousFirmAddress" id="previousFirmAddress"
							   [(ngModel)]="formModel.section2.list.previousFirmAddress.value"
							   #previousFirmAddress="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousFirm"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousFirmAddress.invalid">
						<p *ngIf="previousFirmAddress.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
			</div>
			
			<div class="previousBusiness-wrapper conditional-subsection">
				<div class="conditional-subsection-title">
					{{'Survey.TaxID.Form.PreviousBusinessActivities' | translate}}
					<span *ngIf="lang.active !== 'de'"
						  class="de-translation">(Bisherige betriebliche Verhältnisse)</span>
				</div>
				<div class="conditional-subsection-subtitle"
					 [class.active]="formModel.section2.conditionalSectionsToggle.previousBusiness">
					<div class="form-group previousBusinessActive">
						<div class="input-wrapper">
							<input (change)="toggleQ('section2', 'previousBusiness')" type="checkbox"
								   class="form-control checkbox" name="previousBusinessActive"
								   id="previousBusinessActive"
								   [(ngModel)]="formModel.section2.conditionalSectionsToggle.previousBusiness"
								   #previousBusinessActive="ngModel"/>
							<label for="previousBusinessActive" class="checkbox-label">
								{{'Survey.TaxID.Form.YouWereDoingBusinessInPast5Years' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Wenn Sie in den letzten 5 Jahren gewerbliche oder freiberufliche Tätigkeit in Deutschland ausgeübt haben)</span>
							</label>
						</div>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousBusiness"
					 class="form-group required_section previousBusinessDetails">
					<div class="input-wrapper">
						<label for="previousBusinessDetails">
							{{'Survey.TaxID.Form.Fields2.previousBusinessDetails' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Genaue Beschreibung der Tätigkeit)</span>
							<span class="required_asterisk">*</span>
						</label>
						<textarea class="form-control" name="previousBusinessDetails" id="previousBusinessDetails"
								  [(ngModel)]="formModel.section2.list.previousBusinessDetails.value"
								  #previousBusinessDetails="ngModel"
								  [required]="formModel.section2.conditionalSectionsToggle.previousBusiness"></textarea>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousBusinessDetails.invalid">
						<p *ngIf="previousBusinessDetails.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousBusiness"
					 class="form-group required_section previousBusinessAddress">
					<div class="input-wrapper">
						<label for="previousBusinessAddress">
							{{'Survey.TaxID.Form.Fields2.previousBusinessAddress' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Anschrift: Straße, Nr., PLZ, Stadt)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousBusinessAddress" id="previousBusinessAddress"
							   [(ngModel)]="formModel.section2.list.previousBusinessAddress.value"
							   #previousBusinessAddress="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousBusiness"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousBusinessAddress.invalid">
						<p *ngIf="previousBusinessAddress.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousBusiness"
					 class="form-group required_section previousBusinessStartDate">
					<div class="input-wrapper">
						<label for="previousBusinessStartDate">
							{{'Survey.TaxID.Form.Fields2.previousBusinessStartDate' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Dauer von: Datum eintragen)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appDateValidator class="form-control" placeholder="DD.MM.YYYY"
							   name="previousBusinessStartDate" id="previousBusinessStartDate"
							   [(ngModel)]="formModel.section2.list.previousBusinessStartDate.value"
							   #previousBusinessStartDate="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousBusiness"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousBusinessStartDate.invalid">
						<p *ngIf="previousBusinessStartDate.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="!previousBusinessStartDate.errors.required && previousBusinessStartDate.errors.wrongDateFormat">
							{{'Survey.TaxID.Shared.Wrong_date_format' | translate}}</p>
						<p *ngIf="!previousBusinessStartDate.errors.required && previousBusinessStartDate.errors.invalidDate">
							{{'Survey.TaxID.Shared.Invalid_date' | translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousBusiness"
					 class="form-group required_section previousBusinessEndDate">
					<div class="input-wrapper">
						<label for="previousBusinessEndDate">
							{{'Survey.TaxID.Form.Fields2.previousBusinessEndDate' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Dauer bis: Datum eintragen)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appDateValidator class="form-control" placeholder="DD.MM.YYYY"
							   name="previousBusinessEndDate" id="previousBusinessEndDate"
							   [(ngModel)]="formModel.section2.list.previousBusinessEndDate.value"
							   #previousBusinessEndDate="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousBusiness"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousBusinessEndDate.invalid">
						<p *ngIf="previousBusinessEndDate.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="!previousBusinessEndDate.errors.required && previousBusinessEndDate.errors.wrongDateFormat">
							{{'Survey.TaxID.Shared.Wrong_date_format' | translate}}</p>
						<p *ngIf="!previousBusinessEndDate.errors.required && previousBusinessEndDate.errors.invalidDate">
							{{'Survey.TaxID.Shared.Invalid_date' | translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousBusiness"
					 class="form-group required_section previousBusinessTax">
					<div class="input-wrapper">
						<label for="previousBusinessTax">
							{{'Survey.TaxID.Form.Fields2.previousBusinessTax' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Bisheriges Finanzamt)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="previousBusinessTax" id="previousBusinessTax"
							   [(ngModel)]="formModel.section2.list.previousBusinessTax.value"
							   #previousBusinessTax="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousBusiness"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousBusinessTax.invalid">
						<p *ngIf="previousBusinessTax.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section2.conditionalSectionsToggle.previousBusiness"
					 class="form-group required_section previousBusinessTaxNumber">
					<div class="input-wrapper">
						<label for="previousBusinessTaxNumber">
							{{'Survey.TaxID.Form.Fields2.previousBusinessTaxNumber' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Bisherige Steuernummer)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appNumbersValidator type="number" [min]="0" class="form-control"
							   name="previousBusinessTaxNumber" id="previousBusinessTaxNumber"
							   [(ngModel)]="formModel.section2.list.previousBusinessTaxNumber.value"
							   #previousBusinessTaxNumber="ngModel"
							   [required]="formModel.section2.conditionalSectionsToggle.previousBusiness"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section2Form.submitted && previousBusinessTaxNumber.invalid">
						<p *ngIf="previousBusinessTaxNumber.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="previousBusinessTaxNumber.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!previousBusinessTaxNumber.errors.required && previousBusinessTaxNumber.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
			</div>
			
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection2)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		
		</div>
	
	</form>
	
	<form
		[ngClass]="{'wrapped': !formModel.section3.active, 'ng-submitted-error': section3Form.submitted && section3Form.form.invalid}"
		style="display: none;" #taxIdFormSection3 id="taxIdFormSection3" name="taxIdFormSection3"
		class="taxIdFormSection3 col-12"
		(ngSubmit)="section3Form.form.valid && submitForm(section3Form, 3, taxIdFormSection3)" #section3Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section3.active = !formModel.section3.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section3.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section3.active, 'fa-chevron-circle-down': !formModel.section3.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			<div class="form-group required_section plannedRevenueFromYourBusiness">
				<div class="input-wrapper">
					<label for="plannedRevenueFromYourBusiness">
						{{'Survey.TaxID.Form.Fields3.plannedRevenueFromYourBusiness' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Voraussichtliche Einkünfte aus Ihrer geplanten Tätigkeit (EUR))</span>
					</label>
					<input appNumbersValidator type="number" [min]="0" class="form-control"
						   name="plannedRevenueFromYourBusiness" id="plannedRevenueFromYourBusiness"
						   [(ngModel)]="formModel.section3.list.plannedRevenueFromYourBusiness.value"
						   #plannedRevenueFromYourBusiness="ngModel"/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section3Form.submitted && plannedRevenueFromYourBusiness.invalid">
					<p *ngIf="plannedRevenueFromYourBusiness.errors.required">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}
					</p>
					<p *ngIf="plannedRevenueFromYourBusiness.errors.onlyNumberAvailable">
						{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
					</p>
					<p *ngIf="!plannedRevenueFromYourBusiness.errors.required && plannedRevenueFromYourBusiness.errors.minValueError">
						{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
					</p>
				</div>
			</div>
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection3)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		</div>
	
	</form>
	
	<form
		[ngClass]="{'wrapped': !formModel.section4.active, 'ng-submitted-error': section4Form.submitted && section4Form.form.invalid}"
		style="display: none;" #taxIdFormSection4 id="taxIdFormSection4" name="taxIdFormSection4"
		class="taxIdFormSection4 col-12"
		(ngSubmit)="section4Form.form.valid && submitForm(section4Form, 4, taxIdFormSection4)" #section4Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section4.active = !formModel.section4.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section4.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section4.active, 'fa-chevron-circle-down': !formModel.section4.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			<div class="radiobutton-section-wrapper">
				<div class="radiobutton-uni-lable">
					{{'Survey.TaxID.Form.Fields4.methodOfDeterminingProfit' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Gewinnermittlungsart)</span>
					<span class="required_asterisk">*</span>
				</div>
				<div class="form-group required_section radiobutton-section methodOfDeterminingProfit">
					<div class="input-wrapper">
						<input type="radio" class="form-control radiobutton" name="methodOfDeterminingProfit"
							   id="methodOfDeterminingProfit"
							   [(ngModel)]="formModel.section4.list.methodOfDeterminingProfit.value"
							   value="Einnahmenüberschussrechnung" #methodOfDeterminingProfit="ngModel"/>
						<label for="methodOfDeterminingProfit" class="radiobutton-label">
							{{'Survey.TaxID.Form.Fields4.methodOfDeterminingProfitNo' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Einnahmenüberschussrechnung)</span>
						</label>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section4Form.submitted && methodOfDeterminingProfit.invalid">
						<p *ngIf="methodOfDeterminingProfit.errors.required">
							{{'Survey.TaxID.Shared.Field_is_required' | translate}}
						</p>
					</div>
				</div>
			</div>
			<div class="radiobutton-section-wrapper">
				<div class="radiobutton-uni-lable">
					{{'Survey.TaxID.Form.Fields4.isYourCommercialYearDifferentFromCalendar' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Liegt ein vom Kalenderjahr abweichendes Wirtschaftsjahr vor?)</span>
					<span class="required_asterisk">*</span>
				</div>
				<div class="form-group required_section radiobutton-section isYourCommercialYearDifferentFromCalendar">
					<div class="input-wrapper">
						<input type="radio" value="no" class="form-control radiobutton"
							   name="isYourCommercialYearDifferentFromCalendar"
							   id="isYourCommercialYearDifferentFromCalendar"
							   [(ngModel)]="formModel.section4.list.isYourCommercialYearDifferentFromCalendar.value"
							   #isYourCommercialYearDifferentFromCalendar="ngModel"/>
						<label for="isYourCommercialYearDifferentFromCalendar" class="radiobutton-label">
							{{'Survey.TaxID.Form.Fields4.isYourCommercialYearDifferentFromCalendarNo' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Nein)</span>
						</label>
					</div>
					<div class="input-wrapper">
						<input type="radio" value="yes" class="form-control radiobutton"
							   name="isYourCommercialYearDifferentFromCalendar"
							   id="isYourCommercialYearDifferentFromCalendar2"
							   [(ngModel)]="formModel.section4.list.isYourCommercialYearDifferentFromCalendar.value"
							   #isYourCommercialYearDifferentFromCalendar="ngModel"/>
						<label for="isYourCommercialYearDifferentFromCalendar2" class="radiobutton-label">
							{{'Survey.TaxID.Form.Fields4.isYourCommercialYearDifferentFromCalendarYes' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Ja)</span>
						</label>
					</div>
				</div>
			</div>
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection4)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		</div>
	
	</form>
	
	<form
		[ngClass]="{'wrapped': !formModel.section5.active, 'ng-submitted-error': section5Form.submitted && section5Form.form.invalid}"
		#taxIdFormSection5 id="taxIdFormSection5" name="taxIdFormSection5" class="taxIdFormSection5 col-12"
		(ngSubmit)="section5Form.form.valid && submitForm(section5Form, 5, taxIdFormSection5)" #section5Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section5.active = !formModel.section5.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section5.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section5.active, 'fa-chevron-circle-down': !formModel.section5.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			<div class="form-group required_section checkbox-section exemptionFromConstructionServiceTax">
				<div class="input-wrapper" style="pointer-events: none;">
					<input type="checkbox" class="form-control checkbox" name="exemptionFromConstructionServiceTax"
						   id="exemptionFromConstructionServiceTax"
						   [(ngModel)]="formModel.section5.list.exemptionFromConstructionServiceTax.value"
						   #exemptionFromConstructionServiceTax="ngModel"/>
					<label for="exemptionFromConstructionServiceTax" class="checkbox-label">
						{{'Survey.TaxID.Form.Fields5.exemptionFromConstructionServiceTax' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Ich beantrage die Freistellungsbescheinigung)</span>
						<span class="required_asterisk">*</span>
					</label>
				</div>
				<div class="form-control-feedback"
					 *ngIf="section5Form.submitted && exemptionFromConstructionServiceTax.invalid">
					<p *ngIf="exemptionFromConstructionServiceTax.errors.required">
						{{'Survey.TaxID.Shared.Field_is_required' | translate}}
					</p>
				</div>
			</div>
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection5)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		</div>
	
	</form>
	
	<form
		[ngClass]="{'wrapped': !formModel.section6.active, 'ng-submitted-error': section6Form.submitted && section6Form.form.invalid}"
		#taxIdFormSection6 id="taxIdFormSection6" name="taxIdFormSection6" class="taxIdFormSection6 col-12"
		(ngSubmit)="section6Form.form.valid && submitForm(section6Form, 6, taxIdFormSection6)" #section6Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section6.active = !formModel.section6.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section6.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section6.active, 'fa-chevron-circle-down': !formModel.section6.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			
			<div class="inCaseYouWillHireEmployees-wrapper conditional-subsection">
				<div class="conditional-subsection-subtitle"
					 [class.active]="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees">
					<div class="form-group inCaseYouWillHireEmployeesActive">
						<div class="input-wrapper">
							<input (change)="toggleQ('section6', 'inCaseYouWillHireEmployees')" type="checkbox"
								   class="form-control checkbox" name="inCaseYouWillHireEmployeesActive"
								   id="inCaseYouWillHireEmployeesActive"
								   [(ngModel)]="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"
								   #inCaseYouWillHireEmployeesActive="ngModel"/>
							<label for="inCaseYouWillHireEmployeesActive" class="checkbox-label">
								{{'Survey.TaxID.Form.InCaseYouWillHireEmployees' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Falls Sie sofort mit Beginn der Aktivität Mitarbeiter einstellen)</span>
							</label>
						</div>
					</div>
				</div>
				
				<div *ngIf="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"
					 class="form-group required_section totalNumberOfEmployees">
					<div class="input-wrapper">
						<label for="totalNumberOfEmployees">
							{{'Survey.TaxID.Form.Fields6.totalNumberOfEmployees' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Zahl der Arbeitnehmer insgesamt)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appNumbersValidator type="number" [min]="0" class="form-control"
							   name="totalNumberOfEmployees" id="totalNumberOfEmployees"
							   [(ngModel)]="formModel.section6.list.totalNumberOfEmployees.value"
							   #totalNumberOfEmployees="ngModel"
							   [required]="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section6Form.submitted && totalNumberOfEmployees.invalid">
						<p *ngIf="totalNumberOfEmployees.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="totalNumberOfEmployees.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!totalNumberOfEmployees.errors.required && totalNumberOfEmployees.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				
				<div *ngIf="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"
					 class="form-group required_section familyMembers">
					<div class="input-wrapper">
						<label for="familyMembers">
							{{'Survey.TaxID.Form.Fields6.familyMembers' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Davon Familienangehörige)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appNumbersValidator type="number" [min]="0" class="form-control" name="familyMembers"
							   id="familyMembers" [(ngModel)]="formModel.section6.list.familyMembers.value"
							   #familyMembers="ngModel"
							   [required]="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section6Form.submitted && familyMembers.invalid">
						<p *ngIf="familyMembers.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="familyMembers.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!familyMembers.errors.required && familyMembers.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				
				<div *ngIf="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"
					 class="form-group required_section busyAtMinimumRate">
					<div class="input-wrapper">
						<label for="busyAtMinimumRate">
							{{'Survey.TaxID.Form.Fields6.busyAtMinimumRate' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Davon beschäftigt auf Minijob-Basis)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appNumbersValidator type="number" [min]="0" class="form-control" name="busyAtMinimumRate"
							   id="busyAtMinimumRate" [(ngModel)]="formModel.section6.list.busyAtMinimumRate.value"
							   #busyAtMinimumRate="ngModel"
							   [required]="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section6Form.submitted && busyAtMinimumRate.invalid">
						<p *ngIf="busyAtMinimumRate.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="busyAtMinimumRate.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!busyAtMinimumRate.errors.required && busyAtMinimumRate.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				
				<div *ngIf="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"
					 class="form-group required_section startingPayroll">
					<div class="input-wrapper">
						<label for="startingPayroll">
							{{'Survey.TaxID.Form.Fields6.startingPayroll' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Beginn der Lohnzahlungen: Datum eingeben)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appDateValidator class="form-control" placeholder="DD.MM.YYYY" name="startingPayroll"
							   id="startingPayroll" [(ngModel)]="formModel.section6.list.startingPayroll.value"
							   #startingPayroll="ngModel"
							   [required]="formModel.section6.conditionalSectionsToggle.inCaseYouWillHireEmployees"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section6Form.submitted && startingPayroll.invalid">
						<p *ngIf="startingPayroll.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
						<p *ngIf="!startingPayroll.errors.required && startingPayroll.errors.wrongDateFormat">
							{{'Survey.TaxID.Shared.Wrong_date_format' | translate}}</p>
						<p *ngIf="!startingPayroll.errors.required && startingPayroll.errors.invalidDate">
							{{'Survey.TaxID.Shared.Invalid_date' | translate}}</p>
					</div>
				</div>
			
			</div>
			
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection6)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		
		</div>
	
	</form>
	
	<form
		[ngClass]="{'wrapped': !formModel.section7.active, 'ng-submitted-error': section7Form.submitted && section7Form.form.invalid}"
		#taxIdFormSection7 id="taxIdFormSection7" name="taxIdFormSection7" class="taxIdFormSection7 col-12"
		(ngSubmit)="section7Form.form.valid && submitForm(section7Form, 7, taxIdFormSection7)" #section7Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section7.active = !formModel.section7.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section7.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section7.active, 'fa-chevron-circle-down': !formModel.section7.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			
			<div class="annualTurnOver-wrapper form-group-wrapper">
				<div class="annualTurnOver-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.annualTurnOver' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Summe der Umsätze pro Jahr)</span>
				</div>
				<div class="form-group required_section turnoverInTheYearOfOperation">
					<div class="input-wrapper">
						<label for="turnoverInTheYearOfOperation">
							{{'Survey.TaxID.Form.Fields7.turnoverInTheYearOfOperation' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Im Jahr der Betriebseröffnung (EUR))</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appNumbersValidator type="number" [min]="0" class="form-control"
							   placeholder="{{ 'Survey.TaxID.Form.Fields7.specifyTheAmount' | translate }}"
							   name="turnoverInTheYearOfOperation" id="turnoverInTheYearOfOperation"
							   [(ngModel)]="formModel.section7.list.turnoverInTheYearOfOperation.value"
							   #turnoverInTheYearOfOperation="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section7Form.submitted && turnoverInTheYearOfOperation.invalid">
						<p *ngIf="turnoverInTheYearOfOperation.errors.required">
							{{'Survey.TaxID.Shared.Field_is_required' | translate}}
						</p>
						<p *ngIf="turnoverInTheYearOfOperation.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!turnoverInTheYearOfOperation.errors.required && turnoverInTheYearOfOperation.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
				<div class="form-group required_section turnoverInTheNextYearOfOperation">
					<div class="input-wrapper">
						<label for="turnoverInTheNextYearOfOperation">
							{{'Survey.TaxID.Form.Fields7.turnoverInTheNextYearOfOperation' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Im Folgejahr nach der Betriebseröffnung (EUR))</span>
							<span class="required_asterisk">*</span>
						</label>
						<input appNumbersValidator type="number" [min]="0" class="form-control"
							   placeholder="{{ 'Survey.TaxID.Form.Fields7.specifyTheAmount' | translate }}"
							   name="turnoverInTheNextYearOfOperation" id="turnoverInTheNextYearOfOperation"
							   [(ngModel)]="formModel.section7.list.turnoverInTheNextYearOfOperation.value"
							   #turnoverInTheNextYearOfOperation="ngModel" required/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section7Form.submitted && turnoverInTheNextYearOfOperation.invalid">
						<p *ngIf="turnoverInTheNextYearOfOperation.errors.required">
							{{'Survey.TaxID.Shared.Field_is_required' | translate}}
						</p>
						<p *ngIf="turnoverInTheNextYearOfOperation.errors.onlyNumberAvailable">
							{{'Survey.TaxID.Shared.Only_numbers_available' | translate}}
						</p>
						<p *ngIf="!turnoverInTheNextYearOfOperation.errors.required && turnoverInTheNextYearOfOperation.errors.minValueError">
							{{'Survey.TaxID.Shared.Min_value_error' | translate:{'value':0} }}
						</p>
					</div>
				</div>
			</div>
			
			<div class="saleOfTheCompanyAsAWhole-wrapper form-group-wrapper">
				<div class="saleOfTheCompanyAsAWhole-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.saleOfTheCompanyAsAWhole' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Geschäftsveräußerung im Ganzen (§ 1 Abs. 1a UStG))</span>
				</div>
				<div class="radiobutton-section-wrapper">
					<div class="radiobutton-uni-lable">
						{{'Survey.TaxID.Form.Fields7.purchasedAsWholeOrASeparateUnit' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Es wurde ein Unternehmen oder gesonderter Betrieb erworben?)</span>
						<span class="required_asterisk">*</span>
					</div>
					<div class="form-group required_section radiobutton-section purchasedAsWholeOrASeparateUnit">
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton" name="purchasedAsWholeOrASeparateUnit"
								   id="purchasedAsWholeOrASeparateUnit"
								   [(ngModel)]="formModel.section7.list.purchasedAsWholeOrASeparateUnit.value"
								   value="Yes" #purchasedAsWholeOrASeparateUnit="ngModel" required/>
							<label for="purchasedAsWholeOrASeparateUnit" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.purchasedAsWholeOrASeparateUnitYes' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Ja)</span>
							</label>
						</div>
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton" name="purchasedAsWholeOrASeparateUnit"
								   id="purchasedAsWholeOrASeparateUnit2"
								   [(ngModel)]="formModel.section7.list.purchasedAsWholeOrASeparateUnit.value"
								   value="No" #purchasedAsWholeOrASeparateUnit="ngModel" required/>
							<label for="purchasedAsWholeOrASeparateUnit2" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.purchasedAsWholeOrASeparateUnitNo' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Nein)</span>
							</label>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section7Form.submitted && purchasedAsWholeOrASeparateUnit.invalid">
							<p *ngIf="purchasedAsWholeOrASeparateUnit.errors.required">
								{{'Survey.TaxID.Shared.Field_is_required' | translate}}
							</p>
						</div>
					</div>
				</div>
			</div>
			
			<div class="smallEntrepreneur-wrapper">
				<div class="form-group required_section ng-select-custom">
					<div class="input-wrapper forms-of-enterprise"
						 [class.option-selected]="formModel.section7.list.smallEntrepreneur.value">
						<label for="smallEntrepreneur">
							{{ 'Survey.TaxID.Form.Fields7.smallEntrepreneur' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Kleinunternehmer-Regelung (§ 19 UStG))</span>
							<span class="required_asterisk">*</span>
							<span class="tooltip-wrapper">
                      <i class="fas fa-info-circle"
						 tooltip="{{'Survey.TaxID.Form.Fields7.smallEntrepreneurTooltipText' | translate}}"
						 container="body" placement="right"></i>
                    </span>
						</label>
						<ng-select [items]="formModel.section7.list.smallEntrepreneur.itemsList"
								   [multiple]="false"
								   bindLabel="name"
								   bindValue="id"
								   dropdownPosition="top"
								   name="smallEntrepreneur"
								   id="smallEntrepreneur"
								   [notFoundText]="'Survey.TaxID.Shared.No_clubs_found' | translate"
								   [placeholder]="'Survey.TaxID.Form.Fields7.SelectAnOption' | translate"
								   [closeOnSelect]="true"
								   [hideSelected]="false"
								   [searchable]="false"
								   [clearable]="false"
								   required
								   #smallEntrepreneur="ngModel"
								   [(ngModel)]="formModel.section7.list.smallEntrepreneur.value">
							<ng-template ng-label-tmp let-item="item">
								{{item.name | translate}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item">
								{{item.name | translate}}
							</ng-template>
						</ng-select>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section7Form.submitted && smallEntrepreneur.invalid">
						<p *ngIf="smallEntrepreneur.errors.required">
							{{'Survey.TaxID.Shared.Field_is_required' | translate}}
						</p>
					</div>
				</div>
			</div>
			
			<div style="display: none;" class="mySalesWillBeFreeOfVAT-wrapper form-group-wrapper">
				<div class="mySalesWillBeFreeOfVAT-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.exemtionFromVAT' | translate}}
					<span *ngIf="lang.active !== 'de'"
						  class="de-translation">(Steuerbefreiung von der Umsatzsteuer)</span>
					<span class="tooltip-wrapper">
                  <i class="fas fa-info-circle"
					 tooltip="{{'Survey.TaxID.Form.Fields7.exemtionFromVATTooltipText' | translate}}" container="body"
					 placement="right"></i>
                </span>
				</div>
				<div class="radiobutton-section-wrapper">
					<div class="radiobutton-uni-lable">
						{{'Survey.TaxID.Form.Fields7.mySalesWillBeFreeOfVAT' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Ich werde folgende steuerfreie Umsätze ausführen?)</span>
						<span class="required_asterisk">*</span>
					</div>
					<div class="form-group required_section radiobutton-section mySalesWillBeFreeOfVAT">
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton" name="mySalesWillBeFreeOfVAT"
								   id="mySalesWillBeFreeOfVAT"
								   [(ngModel)]="formModel.section7.list.mySalesWillBeFreeOfVAT.value" value="Yes"
								   #mySalesWillBeFreeOfVAT="ngModel" required/>
							<label for="mySalesWillBeFreeOfVAT" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.mySalesWillBeFreeOfVATYes' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Ja)</span>
							</label>
						</div>
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton" name="mySalesWillBeFreeOfVAT"
								   id="mySalesWillBeFreeOfVAT2"
								   [(ngModel)]="formModel.section7.list.mySalesWillBeFreeOfVAT.value" value="No"
								   #mySalesWillBeFreeOfVAT="ngModel" required/>
							<label for="mySalesWillBeFreeOfVAT2" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.mySalesWillBeFreeOfVATNo' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Nein)</span>
							</label>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section7Form.submitted && mySalesWillBeFreeOfVAT.invalid">
							<p *ngIf="mySalesWillBeFreeOfVAT.errors.required">
								{{'Survey.TaxID.Shared.Field_is_required' | translate}}
							</p>
						</div>
					</div>
				</div>
			</div>
			
			<div style="display: none;" class="mySalesWillHaveReducedRate-wrapper form-group-wrapper">
				<div class="mySalesWillHaveReducedRate-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.VATrate' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Steuersatz)</span>
					<span class="tooltip-wrapper">
                  <i class="fas fa-info-circle" tooltip="{{'Survey.TaxID.Form.Fields7.VATrateTooltipText' | translate}}"
					 container="body" placement="right"></i>
                </span>
				</div>
				<div class="radiobutton-section-wrapper">
					<div class="radiobutton-uni-lable">
						{{'Survey.TaxID.Form.Fields7.mySalesWillHaveReducedRate' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Ich werde Umsätze mit ermäßigtem Steuersatz ausführen?)</span>
						<span class="required_asterisk">*</span>
					</div>
					<div class="form-group required_section radiobutton-section mySalesWillHaveReducedRate">
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton" name="mySalesWillHaveReducedRate"
								   id="mySalesWillHaveReducedRate"
								   [(ngModel)]="formModel.section7.list.mySalesWillHaveReducedRate.value" value="Yes"
								   #mySalesWillHaveReducedRate="ngModel" required/>
							<label for="mySalesWillHaveReducedRate" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.mySalesWillHaveReducedRateYes' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Ja)</span>
							</label>
						</div>
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton" name="mySalesWillHaveReducedRate"
								   id="mySalesWillHaveReducedRate2"
								   [(ngModel)]="formModel.section7.list.mySalesWillHaveReducedRate.value" value="No"
								   #mySalesWillHaveReducedRate="ngModel" required/>
							<label for="mySalesWillHaveReducedRate2" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.mySalesWillHaveReducedRateNo' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Nein)</span>
							</label>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section7Form.submitted && mySalesWillHaveReducedRate.invalid">
							<p *ngIf="mySalesWillHaveReducedRate.errors.required">
								{{'Survey.TaxID.Shared.Field_is_required' | translate}}
							</p>
						</div>
					</div>
				</div>
			</div>
			
			<div style="display: none;" class="willSellGoodsOrServicesWithAverageRates-wrapper form-group-wrapper">
				<div class="willSellGoodsOrServicesWithAverageRates-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.taxationOfVATAtAverageRates' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Durchschnittssatzbesteuerung)</span>
					<span class="tooltip-wrapper">
                  <i class="fas fa-info-circle"
					 tooltip="{{'Survey.TaxID.Form.Fields7.taxationOfVATAtAverageRatesTooltipText' | translate}}"
					 container="body" placement="right"></i>
                </span>
				</div>
				<div class="radiobutton-section-wrapper">
					<div class="radiobutton-uni-lable">
						{{'Survey.TaxID.Form.Fields7.willSellGoodsOrServicesWithAverageRates' | translate}}
						<span *ngIf="lang.active !== 'de'" class="de-translation">(Ich werde Umsätze mit Durchschnittsbesteuerung ausführen?)</span>
						<span class="required_asterisk">*</span>
					</div>
					<div
						class="form-group required_section radiobutton-section willSellGoodsOrServicesWithAverageRates">
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton"
								   name="willSellGoodsOrServicesWithAverageRates"
								   id="willSellGoodsOrServicesWithAverageRates"
								   [(ngModel)]="formModel.section7.list.willSellGoodsOrServicesWithAverageRates.value"
								   value="Yes" #willSellGoodsOrServicesWithAverageRates="ngModel" required/>
							<label for="willSellGoodsOrServicesWithAverageRates" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.willSellGoodsOrServicesWithAverageRatesYes' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Ja)</span>
							</label>
						</div>
						<div class="input-wrapper">
							<input type="radio" class="form-control radiobutton"
								   name="willSellGoodsOrServicesWithAverageRates"
								   id="willSellGoodsOrServicesWithAverageRates2"
								   [(ngModel)]="formModel.section7.list.willSellGoodsOrServicesWithAverageRates.value"
								   value="No" #willSellGoodsOrServicesWithAverageRates="ngModel" required/>
							<label for="willSellGoodsOrServicesWithAverageRates2" class="radiobutton-label">
								{{'Survey.TaxID.Form.Fields7.willSellGoodsOrServicesWithAverageRatesNo' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Nein)</span>
							</label>
						</div>
						<div class="form-control-feedback"
							 *ngIf="section7Form.submitted && willSellGoodsOrServicesWithAverageRates.invalid">
							<p *ngIf="willSellGoodsOrServicesWithAverageRates.errors.required">
								{{'Survey.TaxID.Shared.Field_is_required' | translate}}
							</p>
						</div>
					</div>
				</div>
			</div>
			
			<div style="display: none;" class="VATWithCashOrAccrualMethod-wrapper form-group-wrapper">
				<div class="VATWithCashOrAccrualMethod-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.VATWithCashOrAccrualMethod' | translate}}
					<span *ngIf="lang.active !== 'de'"
						  class="de-translation">(Soll-/Ist-Versteuerung der Umsätze)</span>
				</div>
				<div class="form-group required_section checkbox-section taxMyEarningsByCashMethod">
					<div class="input-wrapper">
						<input type="checkbox" class="form-control checkbox" name="taxMyEarningsByCashMethod"
							   id="taxMyEarningsByCashMethod"
							   [(ngModel)]="formModel.section7.list.taxMyEarningsByCashMethod.value"
							   #taxMyEarningsByCashMethod="ngModel"/>
						<label for="taxMyEarningsByCashMethod" class="checkbox-label">
							{{'Survey.TaxID.Form.Fields7.taxMyEarningsByCashMethod' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Ich berechne die Umsatzsteuer nach (vereinnahmten Umsätzen))</span>
							<span class="required_asterisk">*</span>
						</label>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section7Form.submitted && taxMyEarningsByCashMethod.invalid">
						<p *ngIf="taxMyEarningsByCashMethod.errors.required">
							{{'Survey.TaxID.Shared.Field_is_required' | translate}}
						</p>
					</div>
				</div>
			</div>
			
			<div class="europeanTaxNumberOfVAT-wrapper form-group-wrapper">
				<div class="europeanTaxNumberOfVAT-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.europeanTaxNumberOfVAT' | translate}}
					<span *ngIf="lang.active !== 'de'"
						  class="de-translation">(Umsatzsteuer-Identifikationsnummer)</span>
				</div>
				<div class="form-group required_section checkbox-section iNeedEuropeanTaxNumberOfVAT">
					<div class="input-wrapper">
						<input type="checkbox" class="form-control checkbox" name="iNeedEuropeanTaxNumberOfVAT"
							   id="iNeedEuropeanTaxNumberOfVAT"
							   [(ngModel)]="formModel.section7.list.iNeedEuropeanTaxNumberOfVAT.value"
							   #iNeedEuropeanTaxNumberOfVAT="ngModel"/>
						<label for="iNeedEuropeanTaxNumberOfVAT" class="checkbox-label">
							{{'Survey.TaxID.Form.Fields7.iNeedEuropeanTaxNumberOfVAT' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Ich benötige eine europäische USt-ID-Nummer)</span>
							<span class="required_asterisk">*</span>
						</label>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section7Form.submitted && iNeedEuropeanTaxNumberOfVAT.invalid">
						<p *ngIf="iNeedEuropeanTaxNumberOfVAT.errors.required">
							{{'Survey.TaxID.Shared.Field_is_required' | translate}}
						</p>
					</div>
				</div>
			</div>
			
			<div style="display: none;" class="transitionVATToTheRecipient-wrapper form-group-wrapper">
				<div class="transitionVATToTheRecipient-title form-group-wrapper-title">
					{{'Survey.TaxID.Form.Fields7.transitionVATToTheRecipient' | translate}}
					<span *ngIf="lang.active !== 'de'" class="de-translation">(Steuerschuldnerschaft des Leistungsermpfängers)</span>
				</div>
				<div class="form-group required_section checkbox-section confirmTransitionVATToRecipient">
					<div class="input-wrapper">
						<input type="checkbox" class="form-control checkbox" name="confirmTransitionVATToRecipient"
							   id="confirmTransitionVATToRecipient"
							   [(ngModel)]="formModel.section7.list.confirmTransitionVATToRecipient.value"
							   #confirmTransitionVATToRecipient="ngModel"/>
						<label for="confirmTransitionVATToRecipient" class="checkbox-label">
							{{'Survey.TaxID.Form.Fields7.confirmTransitionVATToRecipient' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Ich werde Bauleistungen oder Gebäudereinigungsleistungen erbringen und benötige den Nachweis zur Steuerschuldnerschaft des Leistungsempfängers)</span>
							<span class="required_asterisk">*</span>
						</label>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section7Form.submitted && confirmTransitionVATToRecipient.invalid">
						<p *ngIf="confirmTransitionVATToRecipient.errors.required">
							{{'Survey.TaxID.Shared.Field_is_required' | translate}}
						</p>
					</div>
				</div>
			</div>
			
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection7)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		
		</div>
	
	</form>
	
	<form
		[ngClass]="{'wrapped': !formModel.section8.active, 'ng-submitted-error': section8Form.submitted && section8Form.form.invalid}"
		#taxIdFormSection8 id="taxIdFormSection8" name="taxIdFormSection8" class="taxIdFormSection8 col-12"
		(ngSubmit)="section8Form.form.valid && submitForm(section8Form, 8, taxIdFormSection8)" #section8Form="ngForm"
		novalidate>
		
		<h4 class="section-title col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"
			(click)="formModel.section8.active = !formModel.section8.active">
			<span>{{'Survey.TaxID.Form.' + formModel.section8.title | translate}}</span>
			<i class="fas"
			   [ngClass]="{'fa-chevron-circle-up': formModel.section8.active, 'fa-chevron-circle-down': !formModel.section8.active}"></i>
		</h4>
		<div class="section-wrapper col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
			
			<div class="ifYouAreAMemberOfThePartnership-wrapper conditional-subsection">
				<div class="conditional-subsection-subtitle"
					 [class.active]="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership">
					<div class="form-group ifYouAreAMemberOfThePartnership">
						<div class="input-wrapper">
							<input (change)="toggleQ('section8', 'ifYouAreAMemberOfThePartnership')" type="checkbox"
								   class="form-control checkbox" name="ifYouAreAMemberOfThePartnership"
								   id="ifYouAreAMemberOfThePartnership"
								   [(ngModel)]="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"
								   #ifYouAreAMemberOfThePartnership="ngModel"/>
							<label for="ifYouAreAMemberOfThePartnership" class="checkbox-label">
								{{'Survey.TaxID.Form.IfYouAreAMemberOfThePartnership' | translate}}
								<span *ngIf="lang.active !== 'de'" class="de-translation">(Wenn Sie Gesellschafter einer Personengesellschaft (GbR, OHG, KG) sind)</span>
							</label>
						</div>
					</div>
				</div>
				<div *ngIf="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"
					 class="form-group required_section partnershipName">
					<div class="input-wrapper">
						<label for="partnershipName">
							{{'Survey.TaxID.Form.Fields8.partnershipName' | translate}}
							<span *ngIf="lang.active !== 'de'"
								  class="de-translation">(Bezeichnung der Gesellschaft)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="partnershipName" id="partnershipName"
							   [(ngModel)]="formModel.section8.list.partnershipName.value" #partnershipName="ngModel"
							   [required]="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section8Form.submitted && partnershipName.invalid">
						<p *ngIf="partnershipName.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"
					 class="form-group required_section partnershipStreet">
					<div class="input-wrapper">
						<label for="partnershipStreet">
							{{'Survey.TaxID.Form.Fields8.partnershipStreet' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Straße)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="partnershipStreet" id="partnershipStreet"
							   [(ngModel)]="formModel.section8.list.partnershipStreet.value"
							   #partnershipStreet="ngModel"
							   [required]="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section8Form.submitted && partnershipStreet.invalid">
						<p *ngIf="partnershipStreet.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"
					 class="form-group required_section partnershipHouseNumber">
					<div class="input-wrapper">
						<label for="partnershipHouseNumber">
							{{'Survey.TaxID.Form.Fields8.partnershipHouseNumber' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Hausnummer)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="partnershipHouseNumber" id="partnershipHouseNumber"
							   [(ngModel)]="formModel.section8.list.partnershipHouseNumber.value"
							   #partnershipHouseNumber="ngModel"
							   [required]="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section8Form.submitted && partnershipHouseNumber.invalid">
						<p *ngIf="partnershipHouseNumber.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"
					 class="form-group partnershipAddressAdditions">
					<div class="input-wrapper">
						<label for="partnershipAddressAdditions">
							{{'Survey.TaxID.Form.Fields8.partnershipAddressAdditions' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Adressergänzung)</span>
						</label>
						<textarea class="form-control" name="partnershipAddressAdditions"
								  id="partnershipAddressAdditions"
								  [(ngModel)]="formModel.section8.list.partnershipAddressAdditions.value"
								  #partnershipAddressAdditions="ngModel"></textarea>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section8Form.submitted && partnershipAddressAdditions.invalid">
						<p *ngIf="partnershipAddressAdditions.errors.required">{{'Survey.TaxID.Shared.Field_is_required'
							| translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"
					 class="form-group required_section partnershipZipcode">
					<div class="input-wrapper">
						<label for="partnershipZipcode">
							{{'Survey.TaxID.Form.Fields8.partnershipZipcode' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Postleitzahl)</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="partnershipZipcode" id="partnershipZipcode"
							   [(ngModel)]="formModel.section8.list.partnershipZipcode.value"
							   #partnershipZipcode="ngModel"
							   [required]="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section8Form.submitted && partnershipZipcode.invalid">
						<p *ngIf="partnershipZipcode.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
				<div *ngIf="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"
					 class="form-group required_section partnershipCity">
					<div class="input-wrapper">
						<label for="partnershipCity">
							{{'Survey.TaxID.Form.Fields8.partnershipCity' | translate}}
							<span *ngIf="lang.active !== 'de'" class="de-translation">(Wohnort (Stadt))</span>
							<span class="required_asterisk">*</span>
						</label>
						<input class="form-control" name="partnershipCity" id="partnershipCity"
							   [(ngModel)]="formModel.section8.list.partnershipCity.value" #partnershipCity="ngModel"
							   [required]="formModel.section8.conditionalSectionsToggle.ifYouAreAMemberOfThePartnership"/>
					</div>
					<div class="form-control-feedback"
						 *ngIf="section8Form.submitted && partnershipCity.invalid">
						<p *ngIf="partnershipCity.errors.required">{{'Survey.TaxID.Shared.Field_is_required' |
							translate}}</p>
					</div>
				</div>
			</div>
			
			<div class="form-controls">
				<div class="controls-wrapper">
					<button (click)="scrollIntoView(taxIdFormSection8)" class="main-action-btn form-submit-btn"
							type="submit">
						{{'Survey.TaxID.Form.Submit_section_btn' | translate}}
					</button>
				</div>
			</div>
		
		</div>
	
	</form>
	
	<div class="form-controls global col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" [ngClass]="{'device': device}">
		<div class="controls-wrapper">
			<button class="f-btn f-btn-main-action form-submit-btn" type="submit" (click)="globalSubmit(formModel)">
				{{'Survey.TaxID.Form.Submit_btn' | translate}}
			</button>
			<button (click)="printForm(formModel)" class="f-btn f-btn-main-action print-btn" type="button">
				{{'Survey.TaxID.Form.Print_btn' | translate}}
			</button>
		</div>
		<div class="form-group sendToEmail">
			<div class="input-wrapper">
				<input type="checkbox" class="form-control checkbox" name="sendToEmail" id="sendToEmail"
					   [(ngModel)]="formModel.sendToEmail" #name="ngModel"/>
				<label for="sendToEmail" class="checkbox-label">
					{{'Survey.TaxID.Form.SendToEmail' | translate}}
				</label>
			</div>
		</div>
	</div>

</div>


<ng-template #tooltipReligionTemplate>
	<div>{{'Survey.TaxID.Form.Fields.Evangelical' | translate}}: <span style="font-weight: bold;">EV</span></div>
	<div>{{'Survey.TaxID.Form.Fields.Roman_catholic' | translate}}: <span style="font-weight: bold;">RK</span></div>
	<div>{{'Survey.TaxID.Form.Fields.Not_subject_to_church_tax' | translate}}: <span
		style="font-weight: bold;">VD</span></div>
</ng-template>
