import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from "./_guards";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { FrontPageComponent } from "./front-page/front-page.component";
import { RegistrationComponent } from "./registration/registration.component";
import { AcceptSmsComponent } from "./registration/accept-sms/accept-sms.component";
import { SetupPwdComponent } from "./registration/setup-pwd/setup-pwd.component";
import { SurveyComponent } from "./survey/survey.component";
import { Error404Component } from "./error404/error404.component";
import { LoginComponent } from "./login/login.component";
import {UserDataProtectionComponent} from "./user-data-protection/user-data-protection.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  /*{ path: 'registration',
    component: RegistrationComponent,
    children: [
      { path: 'accept-sms', component: AcceptSmsComponent },
      { path: 'setup-password', component: SetupPwdComponent }
    ]
  },*/
  { path: '', redirectTo: 'profile', pathMatch: 'full', canActivate: [AuthGuard] },
  {
    path: 'profile',
    loadChildren: './user-profile/user-profile.module#UserProfileModule',
    canActivate: [AuthGuard]
  },
  { path: 'survey', component: SurveyComponent },
  { path: 'data-protection', component: UserDataProtectionComponent },
  { path: '**', component: Error404Component }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  declarations: []
})
export class AppRoutingModule {}
