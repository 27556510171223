import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.less']
})
export class SurveyComponent implements OnInit {
	USER;
	fullReload = false;
	newService = null;
	device = false;
	
	constructor(
		public route: ActivatedRoute
	) {
	}
	
	ngOnInit() {
		console.log(this.route.snapshot.queryParams['newService']);
		if (this.route.snapshot.queryParams['newService']) {
			this.newService = this.route.snapshot.queryParams['newService'];
		}
		if (this.route.snapshot.queryParams['device']) {
			this.device = this.route.snapshot.queryParams['device'];
		}
		this.getUserData();
	}
	
	public fullSurveyReload(): void {
		this.fullReload = true;
	}
	
	getUserData(): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		console.log(this.USER);
	}
}
