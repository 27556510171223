import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SharedService, SurveyService } from "../../_services";
import { NgForm } from "@angular/forms";
import { UserService } from "../../_services/user.service";

@Component({
	selector: 'app-gmbh-form',
	templateUrl: './gmbh-form.component.html',
	styleUrls: ['./gmbh-form.component.less']
})
export class GmbhFormComponent implements OnInit {

	lang: any = {active: localStorage.getItem('lang')};
	device = false;
	formModel: any = {
		sendToEmail: false,
		/*list: {
		  name: '',
		  surname: '',
		  maidenName: '',
		  profession: '',
		  dateOfBirth: '',
		  streetOfResidence: '',
		  houseNumber: '',
		  additionToTheAddress: '',
		  zipCode: '',
		  city: '',
		  tin: '',
		  religiousAffiliationToAParticularChurch: '',
		  dialCode: '',
		  phoneNumber: '',
		  email: '',
		  professionDetails: ''
		}*/
		list: {
			first_name: '',
			last_name: '',
			website: '',
			phone_code: '',
			phone_number: '',
			email: '',
			gmbh_services: '',
			avg_turnover: 0,
			avg_invoices: 0,
			cnt_employees: 0,
			our_services: [
				{
					id: 1,
					name: 'Survey.Gmbh.Form.OurService_1',
					active: false,
				},
				{
					id: 2,
					name: 'Survey.Gmbh.Form.OurService_2',
					active: false,
				},
				{
					id: 3,
					name: 'Survey.Gmbh.Form.OurService_3',
					active: false,
				},
				{
					id: 4,
					name: 'Survey.Gmbh.Form.OurService_4',
					active: false,
				},
				{
					id: 5,
					name: 'Survey.Gmbh.Form.OurService_5',
					active: false,
				},
				{
					id: 6,
					name: 'Survey.Gmbh.Form.OurService_6',
					active: false,
				},
				{
					id: 7,
					name: 'Survey.Gmbh.Form.OurService_7',
					active: false,
				},
				{
					id: 8,
					name: 'Survey.Gmbh.Form.OurService_8',
					active: false,
				},
				{
					id: 9,
					name: 'Survey.Gmbh.Form.OurService_9',
					active: false,
					other_services: ''
				}
			]
		}
	};

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private elem: ElementRef,
		private _SharedService: SharedService,
		private _SurveyService: SurveyService,
		private _UserProfileService: UserService
	) {
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				if (this.isObjectEmpty(this.route.snapshot.queryParams)) {
					this.formModel['path'] = null;
				} else {
					this.formModel['path'] = parseInt(this.route.snapshot.queryParams['path'], 10);
				}
			}
		});
	}

	ngOnInit() {
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			console.log(res);
			this.lang.active = res;
		});

		if (this.route.snapshot.queryParams['device']) {
			this.device = this.route.snapshot.queryParams['device'];
		}
	}

	public isObjectEmpty(obj): boolean {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}

	onchangeValidation(form: NgForm): void {
		let dialCode = '+' + this.elem.nativeElement.querySelector('.phone_number').getAttribute('dialCode');
		let userInput = form.controls['phone_number'].value;
		this.formModel['list']['phone_code'] = dialCode;
		if ((dialCode === '+false' && userInput.length || userInput === '+' + dialCode) && form.submitted) {
			form.controls['phone_number'].setErrors({'wrong_number': true});
		}
	}

	public submitForm(data): void {
		let ourServices = [];
		data['list']['our_services'].forEach((obj, i) => {
			if (obj['active']) {
				if (i === data['list']['our_services'].length - 1) {
					ourServices.push(obj['other_services']);
				} else {
					ourServices.push(obj['id'].toString());
				}
			}
		});

		let objToSend = {
			first_name: data['list']['first_name'],
			last_name: data['list']['last_name'],
			website: data['list']['website'],
			phone_code: data['list']['phone_code'],
			phone_number: data['list']['phone_number'].replace(data['list']['phone_code'], ''),
			email: data['list']['email'],
			gmbh_services: data['list']['gmbh_services'],
			avg_turnover: data['list']['avg_turnover'],
			avg_invoices: data['list']['avg_invoices'],
			cnt_employees: data['list']['cnt_employees'],
			our_services: ourServices
		};
    this.serviceChange({id: 2});
		console.log(objToSend);
		this._SurveyService.sendGmbhData(objToSend)
		.subscribe(res => {
			console.log(res);
			this.serviceChange({id: 2});
		}, err => {
			console.log(err);
		});
	}

	public printForm(data): void {
		window.print();
	}

	public serviceChange(e): void {
    this._UserProfileService.getServices()
      .subscribe(res => {
        //this.listOfUserServices = res.services;
        console.log(res);
        let user = JSON.parse(localStorage.getItem('currentUser'));
        //user['current_service'] = e;
        console.log(e);
        for (var i = 0; i < res.services.length; i++) {
          if (res.services[i].id === e.id) {
            user['current_service'] = res.services[i];
            user['posted_services']['is_post_service_2'] = 1;
            break;
          }
        }
        localStorage.setItem('currentUser', JSON.stringify(user));

        this._UserProfileService.changeUserCurrentService({
          id: e.id
        }).subscribe((res) => {
          console.log('work');
          if (this.route.snapshot.queryParams['device']) {
                    location.replace('/404');
                } else {
                    this.router.navigate(['/profile']);
                }
        }, err => {
          console.log(err);
        })
      }, err => {
        console.log(err);
      });

	}



}
