import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from "rxjs";

@Injectable()
export class SharedService {
	
	changeLanguageEvent: EventEmitter<any> = new EventEmitter();
	emitConnection: EventEmitter<any> = new EventEmitter();
	
	private mobileUserData = new Subject<any>();
	private childLoad = new Subject<any>();
	private serviceSurveyPassed = new Subject<any>();
	passMobileUserData$ = this.mobileUserData.asObservable();
	childLoadEmit$ = this.childLoad.asObservable();
	serviceSurveyPassedEmit$ = this.serviceSurveyPassed.asObservable();
	
	constructor() {
	}
	
	passData(data) {
		this.mobileUserData.next(data);
	}
	
	emitChanges(reload?) {
		this.childLoad.next(reload);
	}
	
	isSurveyPassed(data?) {
		this.serviceSurveyPassed.next(data);
	}
}
