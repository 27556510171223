<header>
	<div class="top-row"></div>
	<div class="bottom-row">
		<div class="container header" id="header">
			<div class="row main-row">
				<div class="col col-12 desktop">
					<a [routerLink]="['']" class="logo">
						<img src="{{'assets/images/proton_logo.png'}}" alt="logo">
					</a>
					<ul class="nav-menu">
						<li *ngIf="user && !isSurveyPassed" class="nav-item survey" [routerLinkActive]="['is-active']">
							<a class="survey_btn" [routerLink]="['survey']">
								{{ 'Header.Survey' | translate }}
							</a>
						</li>
						<li *ngIf="user" class="nav-item user-profile" [routerLinkActive]="['is-active']">
							<a class="user_profile_btn" [routerLink]="['profile']">
								<img src="{{'assets/images/user.png'}}" alt="user photo">
								{{user.name}} {{ user.second_name }}
							</a>
						</li>
						<li *ngIf="user" class="nav-item logout">
							<a class="logout_btn" (click)="logout()">{{ 'Header.Logout' | translate }}</a>
						</li>
						<li class="nav-item language" [class.front-page]="!user">
							<div class="dropdown lang_dropdown_menu_wrapper">
								<button class="lang_dropdown_menu_btn" type="button" id="lang_dropdown_menu"
										data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span *ngIf="lang">{{lang === 'uk' ? 'UA' : lang}}</span>
									<i class="fas fa-chevron-down"></i>
								</button>
								<div class="lang-dropdown-menu dropdown-menu" aria-labelledby="lang_dropdown_menu">
									<a *ngIf="lang !== 'de'" (click)="useLanguage('de')">DE</a>
									<a *ngIf="lang !== 'ru'" (click)="useLanguage('ru')">RU</a>
									<a *ngIf="lang !== 'uk'" (click)="useLanguage('uk')">UA</a>
									<a *ngIf="lang !== 'pl'" (click)="useLanguage('pl')">PL</a>
									<a *ngIf="lang !== 'en'" (click)="useLanguage('en')">EN</a>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="col col-12 mobile" #mobilemenu>
					<a [routerLink]="['']" class="logo">
						<img src="{{'assets/images/proton_logo.png'}}" alt="logo">
					</a>
					<i class="fas fa-bars" (click)="toggleMobileMenu()"></i>
					<div #mobileMenuWrapper class="mobile-menu-wrapper">
						<div class="top-row-mobile"></div>
						<div class="nav-menu main-menu">
							<div class="custom-top-row" *ngIf="user">
								<div *ngIf="!isSurveyPassed" class="nav-item survey">
									<a class="survey_btn" [routerLink]="['survey']">
										{{ 'Header.Survey' | translate }}
									</a>
								</div>
								<div class="nav-item user-profile">
									<a class="user_profile_btn" [routerLink]="['profile']">
										{{user.name}} {{ user.second_name }}
									</a>
								</div>
							</div>
							<div class="custom-bottom-row">
								<div class="nav-item language-menu">
									<div class="lang-item" [class.active]="lang === 'de'" (click)="useLanguage('de')">
										<div>DE</div>
									</div>
									<div class="lang-item" [class.active]="lang === 'ru'" (click)="useLanguage('ru')">
										<div>RU</div>
									</div>
									<div class="lang-item" [class.active]="lang === 'uk'" (click)="useLanguage('uk')">
										<div>UA</div>
									</div>
									<div class="lang-item" [class.active]="lang === 'pl'" (click)="useLanguage('pl')">
										<div>PL</div>
									</div>
									<div class="lang-item" [class.active]="lang === 'en'" (click)="useLanguage('en')">
										<div>EN</div>
									</div>
								</div>
								<div *ngIf="user" class="nav-item logout">
									<a class="logout_btn" (click)="logout()">{{ 'Header.Logout' | translate }}</a>
								</div>
							</div>
						</div>
						<div class="user-profile-menu" *ngIf="user && isProfileRoute">
							<ul class="tab-navigation">
								<li>
									<a [routerLink]="['profile/basic-info']" [routerLinkActive]="['active']">
										<span class="link-title">{{ 'User_profile.Personal_information_tab' | translate }}</span>
									</a>
								</li>
								<li *ngIf="user.current_service.id === 1 || user.current_service.id === 0">
									<a [routerLink]="['profile/documents']" [routerLinkActive]="['active']">
										<span
											class="link-title">{{ 'User_profile.Documents_needed_tab' | translate }}</span>
									</a>
								</li>
								<li *ngIf="(user.current_service.id === 1 || user.current_service.id === 0) && user.need_confirmation">
									<a [routerLink]="['profile/official-confirmation']" [routerLinkActive]="['active']">
										<span class="link-title">{{ 'User_profile.Official_confirmation.Official_confirmation_tab' | translate }}</span>
									</a>
								</li>
								<li *ngIf="user.current_service.id === 3 || user.current_service.id === 4">
									<a [routerLink]="['profile/taxid-documents']" [routerLinkActive]="['active']">
										<span
											class="link-title">{{ 'User_profile.Taxid_documents_tab' | translate }}</span>
									</a>
								</li>
								<li *ngIf="user.current_service.id === 1 || user.current_service.id === 0">
									<a [routerLink]="['profile/declarations']" [routerLinkActive]="['active']">
								<span
									class="link-title">{{ 'User_profile.Ready_declarations.Tab_title' | translate }}</span>
									</a>
								</li>
								<li *ngIf="user.current_service.id === 1 || user.current_service.id === 0">
									<a [routerLink]="['profile/tax-calculations']" [routerLinkActive]="['active']">
										<span class="link-title">{{ 'User_profile.Tax_calculations.Tab_title' | translate }}</span>
									</a>
								</li>
								<li>
									<a [routerLink]="['profile/power-of-attorney']" [routerLinkActive]="['active']">
								<span
									class="link-title">{{ 'User_profile.Power_of_attorney.Tab_title' | translate }}</span>
									</a>
								</li>
								<li>
									<a [routerLink]="['profile/tax-advisor-accounts']" [routerLinkActive]="['active']">
								<span
									class="link-title">{{ 'User_profile.Tax_advisor_accounts.Tab_title' | translate }}</span>
									</a>
								</li>
								<li>
									<a [routerLink]="['profile/feedback']" [routerLinkActive]="['active']">
										<span
											class="link-title">{{ 'User_profile.Feedback.Tab_title' | translate }}</span>
									</a>
								</li>
								<li>
									<a [routerLink]="['profile/change-password']" [routerLinkActive]="['active']">
										<span
											class="link-title">{{ 'User_profile.Change_password_tab' | translate }}</span>
									</a>
								</li>
							</ul>
						</div>
						<!--<div class="nav-menu language-menu">
							<div class="nav-item" [class.active]="lang === 'de'" (click)="useLanguage('de')">
								<div>DE</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'ru'" (click)="useLanguage('ru')">
								<div>RU</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'uk'" (click)="useLanguage('uk')">
								<div>UA</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'pl'" (click)="useLanguage('pl')">
								<div>PL</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'en'" (click)="useLanguage('en')">
								<div>EN</div>
							</div>
						</div>-->
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
