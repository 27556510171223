<ngx-loading-bar></ngx-loading-bar>

<app-header></app-header>
<app-alert></app-alert>

<main #main>
	<router-outlet></router-outlet>
	<div *ngIf="!dataProtection" [ngClass]="{'show': dataProtection === null}" class="data-protection-modal">
		<div class="close-modal" (click)="dataProtection = true">+</div>
		<p>{{'User_data_protection.Modal_text' | translate}}<a href="/data-protection">{{'User_data_protection.Here' |
			translate}}.</a></p>
	</div>
</main>

<app-footer></app-footer>
