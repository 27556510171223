<h1 class="form-title">
	{{ 'Survey.Gmbh.Form.Form_title' | translate }}
</h1>
<h5 class="form-descr col col-sm-12 col-md-9 col-lg-7 col-xl-7">
	{{ 'Survey.Gmbh.Form.Form_descr' | translate }}
</h5>

<div class="form-wrapper col col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
	<form id="gmbhForm" name="gmbhForm" class="gmbhForm col col-12" (ngSubmit)="f.form.valid && submitForm(formModel)"
		  #f="ngForm" [class.ng-submitted-error]="f.submitted && f.form.invalid" novalidate>
		
		<div class="section-wrapper">
			<div class="form-group required_section first_name">
				<div class="input-wrapper">
					<label for="first_name">
						{{'Survey.Gmbh.Form.Name' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="first_name" id="first_name"
						   [(ngModel)]="formModel.list.first_name" #first_name="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && first_name.invalid">
					<p *ngIf="first_name.errors.required">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			
			<div class="form-group required_section last_name">
				<div class="input-wrapper">
					<label for="last_name">
						{{'Survey.Gmbh.Form.Surname' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="last_name" id="last_name" [(ngModel)]="formModel.list.last_name"
						   #last_name="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && last_name.invalid">
					<p *ngIf="last_name.errors.required">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			
			<div class="form-group required_section website">
				<div class="input-wrapper">
					<label for="website">
						{{'Survey.Gmbh.Form.Website' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="website" id="website" [(ngModel)]="formModel.list.website"
						   #website="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && website.invalid">
					<p *ngIf="website.errors.required">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section phone_number_wrapper">
				<div class="input-wrapper">
					<label for="phone_number">
						{{'Survey.Gmbh.Form.PhoneNumber' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<international-phone-number name="phone_number" id="phone_number" placeholder=""
												(ngModelChange)="onchangeValidation(f)" [defaultCountry]="'de'"
												[(ngModel)]="formModel.list.phone_number" [required]="true"
												#phone_number="ngModel"
												[ngClass]="{ 'submitted' : f.submitted}"></international-phone-number>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && phone_number.invalid">
					<p *ngIf="phone_number.errors">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
					<p *ngIf="phone_number.errors && phone_number.errors['wrong_number']">
						{{'Survey.Gmbh.Shared.Phone_number_is_not_valid' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section email">
				<div class="input-wrapper">
					<label for="email">
						{{'Survey.Gmbh.Form.Email' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input appEmailValidator type="email" placeholder="example@domain.com" class="form-control"
						   name="email" id="email" [(ngModel)]="formModel.list.email" #email="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && email.invalid">
					<p *ngIf="email.errors.required">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
					<p *ngIf="!email.errors.required && email.errors.wrongEmailFormat">
						{{'Survey.Gmbh.Shared.Email_is_not_valid' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section gmbh_services">
				<div class="input-wrapper">
					<label for="gmbh_services">
						{{'Survey.Gmbh.Form.Gmbh_services' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input class="form-control" name="gmbh_services" id="gmbh_services"
						   [(ngModel)]="formModel.list.gmbh_services" #gmbh_services="ngModel" required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && gmbh_services.invalid">
					<p *ngIf="gmbh_services.errors.required">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
				</div>
			</div>
			<div class="form-group required_section avg_turnover">
				<div class="input-wrapper">
					<label for="avg_turnover">
						{{'Survey.Gmbh.Form.Avg_turnover' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input appNumbersValidator type="number" [min]="0" class="form-control" name="avg_turnover"
						   id="avg_turnover" [(ngModel)]="formModel.list.avg_turnover" #avg_turnover="ngModel"
						   required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && avg_turnover.invalid">
					<p *ngIf="avg_turnover.errors.required">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
					<p *ngIf="avg_turnover.errors.onlyNumberAvailable">
						{{'Survey.Gmbh.Shared.Only_numbers_available' | translate}}
					</p>
					<p *ngIf="!avg_turnover.errors.required && avg_turnover.errors.minValueError">
						{{'Survey.Gmbh.Shared.Min_value_error' | translate:{'value':0} }}
					</p>
				</div>
			</div>
			<div class="form-group required_section avg_invoices">
				<div class="input-wrapper">
					<label for="avg_invoices">
						{{'Survey.Gmbh.Form.Avg_invoices' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input appNumbersValidator type="number" [min]="0" class="form-control" name="avg_invoices"
						   id="avg_invoices" [(ngModel)]="formModel.list.avg_invoices" #avg_invoices="ngModel"
						   required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && avg_invoices.invalid">
					<p *ngIf="avg_invoices.errors.required">{{'Survey.Gmbh.Shared.Field_is_required' | translate}}</p>
					<p *ngIf="avg_invoices.errors.onlyNumberAvailable">
						{{'Survey.Gmbh.Shared.Only_numbers_available' | translate}}
					</p>
					<p *ngIf="!avg_invoices.errors.required && avg_invoices.errors.minValueError">
						{{'Survey.Gmbh.Shared.Min_value_error' | translate:{'value':0} }}
					</p>
				</div>
			</div>
			<div class="form-group required_section cnt_employees">
				<div class="input-wrapper">
					<label for="cnt_employees">
						{{'Survey.Gmbh.Form.Cnt_employees' | translate}}
						<span class="required_asterisk">*</span>
					</label>
					<input appNumbersValidator type="number" [min]="0" class="form-control" name="cnt_employees"
						   id="cnt_employees" [(ngModel)]="formModel.list.cnt_employees" #cnt_employees="ngModel"
						   required/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && cnt_employees.invalid">
					<p *ngIf="cnt_employees.errors.required">{{'Shared.Field_is_required' | translate}}</p>
					<p *ngIf="cnt_employees.errors.onlyNumberAvailable">
						{{'Survey.Gmbh.Shared.Only_numbers_available' | translate}}
					</p>
					<p *ngIf="!cnt_employees.errors.required && cnt_employees.errors.minValueError">
						{{'Survey.Gmbh.Shared.Min_value_error' | translate:{'value':0} }}
					</p>
				</div>
			</div>
			
			<fieldset class="checkboxgroup">
				<legend>{{ 'Survey.Gmbh.Form.Our_services_title' | translate }}</legend>
				<div class="form-group our_services" *ngFor="let i of formModel.list.our_services; let index = index"
					 [ngClass]="{'checked': i.active}">
					<div class="input-wrapper checkbox-form-group">
						<input type="checkbox" class="form-control checkbox" name="our_services_{{i.id}}"
							   id="our_services_{{i.id}}" [(ngModel)]="formModel.list.our_services[index].active"
							   #our_services="ngModel"/>
						<label for="our_services_{{i.id}}" class="checkbox-label">
							{{i.name | translate}}
						</label>
					</div>
					
					<div
						*ngIf="formModel.list.our_services[formModel.list.our_services.length - 1]['active'] && formModel.list.our_services.length - 1 === index"
						class="form-group other_services">
						<div class="input-wrapper">
							<label for="other_services"></label>
							<textarea class="form-control"
									  placeholder="{{ 'Survey.Gmbh.Form.OtherServices' | translate }}"
									  name="other_services" id="other_services"
									  [(ngModel)]="formModel.list.our_services[formModel.list.our_services.length - 1]['other_services']"
									  #other_services="ngModel"></textarea>
						</div>
					</div>
				</div>
			</fieldset>
		
		</div>
		
		<div class="form-controls" [ngClass]="{'device': device}">
			<div class="controls-wrapper">
				<button class="f-btn f-btn-main-action form-submit-btn" type="submit">
					{{'Survey.Gmbh.Form.Submit_btn' | translate}}
				</button>
				<button (click)="printForm(formModel)" class="f-btn f-btn-main-action print-btn" type="button">
					{{'Survey.Gmbh.Form.Print_btn' | translate}}
				</button>
			</div>
			
			<div class="form-group sendToEmail">
				<div class="input-wrapper">
					<input type="checkbox" class="form-control checkbox" name="sendToEmail" id="sendToEmail"
						   [(ngModel)]="formModel.sendToEmail" #name="ngModel"/>
					<label for="sendToEmail" class="checkbox-label">
						{{'Survey.Gmbh.Form.SendToEmail' | translate}}
					</label>
				</div>
			</div>
		</div>
	
	</form>
</div>




