import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { TranslateService } from "@ngx-translate/core";

import { Alert, AlertType } from "../_models";


@Injectable()
export class AlertService {
	private translated_msg: any;
	private subject = new Subject<Alert>();
	private keepAfterRouteChange = false;
	
	constructor(private router: Router, private translate: TranslateService) {
		// clear alert messages on route change unless 'keepAfterRouteChange' flag is true
		router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterRouteChange) {
					// only keep for a single route change
					this.keepAfterRouteChange = false;
				} else {
					// clear alert messages
					this.clear();
				}
			}
		});
	}
	
	getAlert(): Observable<any> {
		return this.subject.asObservable();
	}
	
	success(message: string, keepAfterRouteChange = true) {
		this.alert(AlertType.Success, message, keepAfterRouteChange);
	}
	
	error(message: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Error, message, keepAfterRouteChange);
	}
	
	info(message: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Info, message, keepAfterRouteChange);
	}
	
	warn(message: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Warning, message, keepAfterRouteChange);
	}
	
	alert(type: AlertType, message: string, keepAfterRouteChange = false) {
		this.translate.get(message).subscribe((res: Object) => {
			this.translated_msg = res;
		});
		this.keepAfterRouteChange = keepAfterRouteChange;
		this.subject.next(<Alert>{type: type, message: this.translated_msg});
	}
	
	clear() {
		// clear alerts
		this.subject.next();
	}
}
