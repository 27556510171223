import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SurveyService, SharedService, AlertService } from "../../_services";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import * as moment_ from 'moment';
import { NgSelectComponent } from "@ng-select/ng-select";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: 'app-auth-survey',
	templateUrl: './auth-survey.component.html',
	styleUrls: ['./auth-survey.component.less']
})
export class AuthSurveyComponent implements OnInit {
	
	@ViewChild('officialConfirmationModal') officialConfirmationModal;
	
	public USER;
	modalRef: BsModalRef;
	periodsForm: FormGroup;
	customAnswerValueForm: FormGroup;
	
	lang: any = {active: localStorage.getItem('lang')};
	
	answerIsNotChosen = true;
	continueSurveyAfterYearSelection = false;
	askUserAboutChanges = false;
	
	periods: {
		id: number,
		year: string
	}[];
	QAObject: {
		id: number;
		description: string;
		description2: string;
		tree_end?: number;
		registration?: number;
		answer_chosen?: boolean;
		multi_answer?: boolean;
		type?: number;
		answers: any[];
	};
	periodsSelected: any = [];
	activeYear: any = {};
	yearRestriction: {} = {
		start: null,
		end: null
	};
	customMultipleValues: {}[] = [];
	
	device = null;
	
	mPeriodId: number;
	mPeriodName: number;
	
	backBtnLock = false;
	
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private _SurveyService: SurveyService,
		private modalService: BsModalService,
		private fb: FormBuilder,
		private _SharedService: SharedService,
		private _AlertService: AlertService,
		private translate: TranslateService
	) {
	}
	
	ngOnInit() {
		this.getUserData();
		this.getPeriods();
		if (this.lang['active'] === null) {
			this.lang['active'] = 'ru';
		}
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang.active = res;
		});
	}
	
	getUserData(): void {
		console.log(this.route.snapshot.queryParams['passed']);
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		
		if (this.route.snapshot.queryParams['device']) {
			this.device = true;
			if (this.route.snapshot.queryParams['period_id']) {
				this.mPeriodId = parseInt(this.route.snapshot.queryParams['period_id'], 10);
				this.mPeriodName = parseInt(this.route.snapshot.queryParams['year'], 10);
				this.USER.user_periods.forEach(obj => {
					obj['active'] = false;
				});
				this.USER['last_period_id'] = this.mPeriodId;
				this.USER['last_question_id'] = '';
				this.USER['next_question_id'] = '';
				this.USER['last_answer_id'] = [];
				this.USER.user_periods.push({
					id: this.mPeriodId,
					year: this.mPeriodName,
					active: true
				});
				
				this.activeYear = this.USER.user_periods[this.USER.user_periods.length - 1];
				
				this.continueSurveyAfterYearSelection = true;
			}
		}
		
		if (this.route.snapshot.queryParams['passed']) {
			this.askUserAboutChanges = false;
			this.continueSurveyAfterYearSelection = false;
		} else {
			if (this.USER.user_periods.length && (this.USER.last_period_id !== null && this.USER.last_period_id)) {
				this.USER.user_periods.forEach(obj => {
					obj['active'] = false;
					if (obj['id'] === this.USER.last_period_id) {
						obj['active'] = true;
						this.activeYear = obj;
					}
				});
				
				let q = (!this.USER.next_question_id) ? '' : this.USER.next_question_id;
				let p = (!this.USER.last_period_id || this.USER.last_period_id === null) ? '' : this.USER.last_period_id;
				this.getQuestion(q, p, true, this.USER);
			} else {
				this.USER['last_period_id'] = null;
				this.USER['last_question_id'] = '';
				this.USER['next_question_id'] = '';
				this.USER['last_answer_id'] = [];
				localStorage.setItem('currentUser', JSON.stringify(this.USER));
			}
		}
		
		this.previousQuestionsCheck();
	}
	
	openOfficialConfirmationModal(): void {
		this.modalRef = this.modalService.show(
			this.officialConfirmationModal,
			Object.assign({
				'class': 'official-confirmation-modal modal-dialog-centered',
				'ignoreBackdropClick': true
			})
		);
	}
	public officialConfirmation(): void {
		this.closeModal();
		this._SurveyService.getQuestionQ({
			question_id: this.QAObject.id,
			period_id: this.activeYear.id
		}).subscribe(obj => {
			console.log(obj);
		})
	}
	
	public periodsFormInit(): void {
		this.periodsForm = this.fb.group({
			periods: [[], Validators.required]
		});
	}
	
	public customAnswerValueFormInit(): void {
		this.yearRestriction = {
			start: moment_(`${this.activeYear.year}-01-01`).startOf('year').toDate(),
			end: moment_(`${this.activeYear.year}-01-01`).endOf('year').toDate()
		};
		this.customAnswerValueForm = this.fb.group({
			value: ['', [Validators.required]],
			date: [this.yearRestriction['start'], [Validators.required]],
			mutipleValue: ['', [Validators.required]]
		});
	}
	
	// COMMON METHODS
	public getPeriods(): void {
		this._SurveyService.getPeriods()
		.subscribe(res => {
			this.periods = res.result;
			this.periodsFormInit();
		});
	}
	
	public chooseAnswers(i, clear?): void {
		if (i && !this.QAObject.multi_answer) {
			// IF USER ANSWERED AND THiS IS A SINGLE-ANSWER QUESTION
			for (let q = 0; q < this.QAObject.answers.length; q++) {
				this.QAObject.answers[q].answer_chosen = false;
			}
			i['answer_chosen'] = true;
			this.answerIsNotChosen = false;
		} else if (i && this.QAObject.multi_answer) {
			// IF USER ANSWERED AND THiS IS A MULTI-ANSWER QUESTION
			if (i['answer_chosen']) {
				i['answer_chosen'] = false;
				this.answerIsNotChosen = true;
				for (let q = 0; q < this.QAObject.answers.length; q++) {
					if (this.QAObject.answers[q].answer_chosen) {
						this.answerIsNotChosen = false;
					}
				}
			} else {
				i['answer_chosen'] = true;
				this.answerIsNotChosen = false;
			}
		}
		
		if (clear) {
			if (this.QAObject) {
				for (let q = 0; q < this.QAObject.answers.length; q++) {
					this.QAObject.answers[q].answer_chosen = false;
				}
			}
			this.answerIsNotChosen = true;
		}
	}
	
	public addMoreCustomValueInputs(): void {
		this.customMultipleValues.push(
			{
				id: this.customMultipleValues.length,
				name: `customValue${this.customMultipleValues.length}`,
				value: ''
			}
		);
		this.customAnswerValueForm.addControl(
			this.customMultipleValues[this.customMultipleValues.length - 1]['name'],
			new FormControl('')
		);
	}
	
	public closeSelect(select: NgSelectComponent) {
		select.close();
	}
	
	public closeModal(): void {
		this.chooseAnswers(null, true);
		this.modalRef.hide();
	}
	
	public isObjectEmpty(obj): boolean {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}
	
	public previousQuestionsCheck(): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		if (this.USER.user_periods.length) {
			this.USER.user_periods.forEach(obj => {
				if (obj['active']) {
					if (this.isObjectEmpty(obj['attrs'])) {
						this.backBtnLock = true;
					} else {
						(obj['attrs']['previous_questions'].length) ? this.backBtnLock = false : this.backBtnLock = true;
					}
				}
			});
		}
	}
	
	// GLOBAL GET QUESTION AND ANSWER QUESTION METHODS
	public getQuestion(question_id, period_id, onLoad?, userObject?): void {
		this._SurveyService.getQuestionQ({
			question_id: question_id,
			period_id: period_id
		}).subscribe(res => {
			this.answerIsNotChosen = true;
			this.USER['last_question_id'] = res.result['id'];
			this.QAObject = res.result;
			if (this.QAObject['tree_end'] === -1) {
				this.QAObject['tree_end'] = 0;
			}
			for (let i = 0; i < this.QAObject.answers.length; i++) {
				for (let z = 0; z < this.QAObject.answers[i].attributes_id.length; z++) {
					if (this.QAObject.answers[i].attributes_id[z].type > 0 && this.QAObject.answers[i].attributes_id[z].type !== 6) {
						this.QAObject['type'] = this.QAObject.answers[i].attributes_id[z];
					}
				}
			}
			
			localStorage.setItem('currentUser', JSON.stringify(userObject));
			this.customAnswerValueFormInit();
			this.previousQuestionsCheck();
			
			if (onLoad) {
				(this.device && this.QAObject['tree_end']) ? this.continueSurveyAfterYearSelection = false : this.continueSurveyAfterYearSelection = true;
				// localStorage.removeItem('afterLogin');
			} else {
				setTimeout(() => {
					this.continueSurveyAfterYearSelection = true;
					this.askUserAboutChanges = false;
				}, 500);
			}
			window.scroll(0, 0);
		}, err => {
			console.log(err);
		});
		
	}
	
	public submitAnswer(question_id, answer_id, period_id, answer_value?): void {
		if (question_id === 1) {
			this.resetUserAttrs([this.USER['last_period_id']]);
		}
		
		this._SurveyService.submitAnswer({
			question_id: question_id,
			answer_id: answer_id,
			value: answer_value,
			period_id: period_id
		}).subscribe(res => {
			console.log(res);
			this.USER['last_question_id'] = res.result['id'];
			this.USER['next_question_id'] = res.result['id'];
			this.USER['last_answer_id'] = answer_id;
			this.USER['last_period_id'] = period_id;
			
			this.QAObject = res.result;
			for (let ii = 0; ii < this.QAObject.answers.length; ii++) {
				for (let z = 0; z < this.QAObject.answers[ii].attributes_id.length; z++) {
					if (this.QAObject.answers[ii].attributes_id[z].type > 0 && this.QAObject.answers[ii].attributes_id[z].type !== 6) {
						this.QAObject['type'] = this.QAObject.answers[ii].attributes_id[z];
					}
				}
			}
			this.answerIsNotChosen = true;
			localStorage.setItem('currentUser', JSON.stringify(this.USER));
			this.previousQuestionsCheck();
			if (res.result.tree_end) {
				this.openOfficialConfirmationModal();
			}
			window.scroll(0, 0);
		}, err => {
			console.log(err);
			if (err.error.message === "Questionnaire limit error") {
				this._AlertService.error("Alert.Max_attempts_survey")
			}
		});
	}
	
	
	// AUTHORIZED USER METHODS
	public setPeriods(template): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		this.mPeriodId = null;
		let yearChosen = this.periodsForm.value.periods;
		yearChosen['attrs'] = {};
		yearChosen['active'] = true;
		this.activeYear = yearChosen;
		
		if (!this.USER.user_periods.length) {
			this.USER['user_periods'].push(this.activeYear);
			this.USER['last_period_id'] = this.activeYear['id'];
			this.USER.user_periods.forEach((obj, i) => {
				obj['attrs'] = {
					attributes_id: []
				};
				if (obj['id'] === this.USER.last_period_id) {
					obj['attrs']['question_id'] = this.USER.last_question_id;
					obj['attrs']['answer_id'] = this.USER.last_answer_id;
					obj['attrs']['previous_questions'] = [];
					obj['active'] = true;
					this.activeYear = this.USER.user_periods[i];
				}
			});
			
			this.getQuestion(this.USER.last_question_id, this.USER.last_period_id, false, this.USER);
		} else {
			const hasDuplicate = this.USER.user_periods.some(obj => obj['id'] === this.activeYear['id']);
			this.USER.user_periods.forEach(obj => {
				obj['active'] = false;
			});
			if (hasDuplicate) {
				this.modalRef = this.modalService.show(
					template,
					Object.assign({'class': 'repetition-alert-modal modal-dialog-centered'})
				);
			} else {
				this.USER.user_periods.push(this.activeYear);
				localStorage.setItem('currentUser', JSON.stringify(this.USER));
				
				/*this.askUserAboutChanges = true;
				this.continueSurveyAfterYearSelection = false;*/
				this.getQuestion('', this.activeYear['id'], false, this.USER);
			}
		}
		if (this.customAnswerValueForm) {
			this.customAnswerValueForm.reset();
			this.customMultipleValues = [];
		}
		
		if (this.route.snapshot.queryParams['passed']) {
			this.router.navigate([], {
				queryParams: {
					passed: null
				},
				queryParamsHandling: 'merge'
			});
		}
		
	}
	
	public copyPeriod(p_period_id, n_period_id): void {
		this._SurveyService.noChangesInNextPeriod({
			new_period_id: p_period_id,
			old_period_id: n_period_id
		}).subscribe(res => {
			console.log(res);
		}, err => {
			console.log(err);
		});
	}
	
	public resetUserAttrs(period_id: any[]): void {
		this._SurveyService.resetUserAttrs({
			period_ids: period_id
		}).subscribe(res => {
		
		})
	}
	
	public repetitionAlertConfirmation(): void {
		this.closeModal();
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		let yearChosen = this.activeYear;
		yearChosen['attrs'] = {};
		this.USER.user_periods.forEach(obj => {
			obj['active'] = false;
		});
		this.USER['last_question_id'] = '';
		this.USER['next_question_id'] = '';
		this.USER['last_period_id'] = yearChosen['id'];
		this.USER['last_answer_id'] = [];
		for (let i = 0; i < this.USER.user_periods.length; i++) {
			if (this.USER.user_periods[i]['id'] === yearChosen['id']) {
				this.USER.user_periods[i] = yearChosen;
				let cutOut = this.USER.user_periods.splice(i, 1)[0];
				this.USER.user_periods.push(cutOut);
				break;
			}
		}
		this.getQuestion(this.USER['last_question_id'], this.USER['last_period_id'], false, this.USER);
		this.resetUserAttrs([this.USER['last_period_id']]);
	}
	
	public authAnswerQuestion(): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		
		let objToSend = {};
		let arr = [];
		let answers_array = [];
		for (let q = 0; q < this.QAObject.answers.length; q++) {
			if (this.QAObject.answers[q].answer_chosen) {
				objToSend['question_id'] = this.QAObject.id;
				answers_array.push(this.QAObject.answers[q].id);
				for (let f = 0; f < this.QAObject.answers[q].attributes_id.length; f++) {
					this.QAObject.answers[q].attributes_id[f]['value'] = '';
					arr.push(this.QAObject.answers[q].attributes_id[f]);
				}
			}
		}
		objToSend['answer_id'] = answers_array;
		objToSend['attributes_id'] = arr;
		this.USER.user_periods.forEach(res => {
			if (res['active']) {
				if (this.isObjectEmpty(res['attrs'])) {
					res['attrs'] = {
						question_id: objToSend['question_id'],
						answer_id: objToSend['answer_id'],
						attributes_id: objToSend['attributes_id'],
						previous_questions: [objToSend['question_id']]
					};
				} else {
					let b = res['attrs'].attributes_id.concat(objToSend['attributes_id']);
					b = b.filter((item, index, self) =>
						index === self.findIndex((t) => (
							t.id === item.id
						))
					);
					res['attrs'].previous_questions.push(objToSend['question_id']);
					res['attrs'] = {
						question_id: objToSend['question_id'],
						answer_id: objToSend['answer_id'],
						attributes_id: b,
						previous_questions: res['attrs'].previous_questions
					};
				}
			}
		});
		
		this.submitAnswer(objToSend['question_id'], objToSend['answer_id'], this.activeYear['id'], '');
	}
	
	public authCustomAnswerValue(type): void {
		let multipleString = '';
		if (type['type'] === 2) {
			this.QAObject.type['value'] = moment_(this.customAnswerValueForm.value.date, "DD-MM-YYYY").format("YYYY-MM-DD");
		} else if (type['type'] === 1) {
			this.QAObject.type['value'] = this.customAnswerValueForm.value.value;
		} else {
			multipleString = this.customAnswerValueForm.controls['mutipleValue'].value;
			for (let i = 0; i < this.customMultipleValues.length; i++) {
				if (this.customAnswerValueForm.controls[this.customMultipleValues[i]['name']].value) {
					multipleString = multipleString + '~' + this.customAnswerValueForm.controls[this.customMultipleValues[i]['name']].value;
				}
			}
		}
		
		this.USER.user_periods.forEach(res => {
			if (res['active']) {
				if (this.isObjectEmpty(res['attrs'])) {
					res['attrs'] = {
						question_id: this.QAObject.id,
						answer_id: this.QAObject.answers[0].id,
						attributes_id: [this.QAObject.type],
						previous_questions: [this.QAObject.id]
					};
				} else {
					res['attrs'].attributes_id.push(this.QAObject.type);
					res['attrs'].previous_questions.push(this.QAObject.id);
					res['attrs'] = {
						question_id: this.QAObject.id,
						answer_id: this.QAObject.answers[0].id,
						attributes_id: res['attrs'].attributes_id,
						previous_questions: res['attrs'].previous_questions
					};
				}
			}
		});
		
		this.submitAnswer(this.QAObject.id, this.QAObject.answers[0].id, this.activeYear['id'], (type['type'] === 3) ? multipleString : this.QAObject.type['value']);
	}
	
	public authRerunSurvey(): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		this.USER['last_question_id'] = '';
		this.USER['next_question_id'] = '';
		this.USER['last_answer_id'] = [];
		
		this.customAnswerValueForm.controls['value'].setValue('');
		this.customAnswerValueForm.controls['date'].setValue(moment_().format('DD-MM-YYYY'));
		for (let i = this.USER.user_periods.length; i--;) {
			if (this.USER.user_periods[i]['id'] === this.activeYear['id']) {
				this.USER.user_periods[i]['attrs'] = {};
				this.USER['last_period_id'] = this.activeYear['id'];
				this.getQuestion(this.USER['last_question_id'], this.USER['last_period_id'], false, this.USER);
				break;
			}
		}
		
		if (this.customAnswerValueForm) {
			this.customAnswerValueForm.reset();
			this.customMultipleValues = [];
		}
	}
	
	public authRunSurveyForNextYear(): void {
		this.continueSurveyAfterYearSelection = false;
		this.periodsForm.reset();
		window.scroll(0, 0);
	}
	
	public exitToApp(): void {
		if (this.route.snapshot.queryParams['device']) {
			location.replace('/404');
		}
	}
	
	public backToYearSelection(): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		this.USER['last_question_id'] = '';
		this.USER['next_question_id'] = '';
		this.USER['last_answer_id'] = [];
		this.USER['last_period_id'] = null;
		localStorage.setItem('currentUser', JSON.stringify(this.USER));
		this.periodsForm.reset();
		this.continueSurveyAfterYearSelection = false;
		this.previousQuestionsCheck();
		window.scroll(0, 0);
	}
	
	public authContinueSurvey(): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		this.USER['last_period_id'] = this.activeYear['id'];
		this.getQuestion('', this.USER.last_period_id, false, this.USER);
		
	}
	
	public authNoChangesInNextYear(): void {
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		let periodToCopy = this.USER.user_periods[this.USER.user_periods.length - 2]['id'];
		this.USER['last_period_id'] = this.activeYear['id'];
		this.copyPeriod(this.USER['last_period_id'], periodToCopy);
		this.getQuestion(this.USER['next_question_id'], periodToCopy, false, this.USER);
	}
	
	public authPreviousQuestion(): void {
		if (this.customAnswerValueForm) {
			this.customAnswerValueForm.reset();
			this.customMultipleValues = [];
		}
		
		this.USER = JSON.parse(localStorage.getItem('currentUser'));
		let prevQ = [];
		this.USER.user_periods.forEach(obj => {
			if (obj['active']) {
				if (!this.isObjectEmpty(obj['attrs'])) {
					prevQ = Array.from(obj['attrs']['previous_questions']);
					obj['attrs']['previous_questions'].pop();
				}
			}
		});
		
		this.getQuestion(prevQ[prevQ.length - 1], this.activeYear['id'], false, this.USER);
	}
	
	public proceedToDocScan(): void {
		if (this.route.snapshot.queryParams['device']) {
			location.replace('/404');
		} else {
			this.router.navigate(['profile/documents']);
		}
	}
	
}
