import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "../../../_services";

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
	
	@ViewChild('globalFooter') globalFooter: ElementRef;
	
	lang = localStorage.getItem('lang');
	
	constructor(
		public router: Router,
		public route: ActivatedRoute,
		private _SharedService: SharedService
	) {
		this.lang = this.lang === 'uk' ? 'ua' : this.lang;
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang = res === 'uk' ? 'ua' : res;
		});
	}
	
	ngOnInit() {
	}
}
