import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { EnvService } from "./env.service";

@Injectable()
export class UserService {
	
	private USER = JSON.parse(localStorage.getItem("currentUser"));
	
	getUserData(): void {
		this.USER = JSON.parse(localStorage.getItem("currentUser"));
	}
	
	constructor(
		private env: EnvService,
		private http: HttpClient
	) {
	}
	
	public getServices(): Observable<any> {
		const url = `${this.env.apiBase}/user/service`;
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_Id", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getUserAvailableServices(): Observable<any> {
		const url = `${this.env.apiBase}/user/service-available`;
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public changeUserCurrentService(data): Observable<any> {
		const url = `${this.env.apiBase}/user/current-service`;
		let params = new HttpParams()
		.append('userId', this.USER.user_id)
		.append('user_id', this.USER.user_id)
		.append('current_service_id', data.id);
		return this.http.post<Object>(url, {}, {params})
	}
	
	public addServiceForUser(data): Observable<any> {
		const url = `${this.env.apiBase}/user/service`;
		let params = new HttpParams()
		.append('userId', this.USER.user_id)
		.append('user_id', this.USER.user_id)
		.append('service_id', data.id)
		.append('is_current', '1');
		return this.http.post<Object>(url, {}, {params})
	}
	
	public getPeriods(): Observable<any> {
		const url = `${this.env.apiBase}/period`;
		return this.http.get<Object>(url);
	}
	
	public getMobileUsers(): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getAttributes(): Observable<any> {
		const url = `${this.env.apiBase}/attribute`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getSubmittedUserDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("with_pdf", "1")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public downloadDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/pdf`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_owner_id", this.USER.user_id)
		.append("language_code", data.language_code)
		.append("sub_category_id", data.sub_category_id)
		.append("period_id", data.period_id)
		.append("type", "1")
		.append("document_id", data.document_id);
		
		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}
	
	public downloadDefDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/pdf`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_owner_id", this.USER.user_id)
		.append("language_code", data.language_code)
		.append("sub_category_id", data.sub_category_id)
		.append("period_id", data.period_id)
		.append("type", data.type)
		.append("document_id", data.document_id);
		
		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}
	
	//downloadPOADocuments
	
	public getRegisteredLanguages(): Observable<any> {
		const url = `${this.env.apiBase}/language`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getSpecificIsoLangNames(array): any {
		return this.http.get<any>("/assets/ISO-631-1.json")
		.map((res) => {
			let arrayFixed = [];
			for (let i = 0; i < array.length; i++) {
				for (let key of Object.keys(res)) {
					if (array[i].lang_code === key.toUpperCase()) {
						for (let key2 of Object.keys(res[key])) {
							array[i]["name"] = res[key].name;
							array[i]["native_name"] = res[key].nativeName;
							arrayFixed.push(array[i]);
							break;
						}
					}
				}
			}
			return arrayFixed;
		});
	}
	
	public postDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/form-data`;
		// const url = `${this.env.apiBase}/user/document`;
		let formData = new FormData();
		formData.append('period_id', data.period_id);
		formData.append('tags[]', data.tags);
		formData.append('sub_category_id', data.sub_category_id);
		formData.append('document_id', data.document_id);
		formData.append('description', data.description);
		formData.append('part_name', data.part_name);
		formData.append('user_service_id', data.user_service_id);
		data.files_array.forEach(o => {
			formData.append('photos_array[]', o);
		});
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_service_id", data.user_service_id);
		return this.http.post<Object>(url, formData, {params});
	}
	
	public postDocumentsToSpecificDoc(data): Observable<any> {
		console.log(data);
		// const url = `${this.env.apiBase}/user/document/${data.document_id}/add-item`;
		const url = `${this.env.apiBase}/user/document/${data.document_id}/add-item/form-data`;
		let formData = new FormData();
		formData.append('period_id', data.period_id);
		formData.append('tags[]', data.tags);
		formData.append('sub_category_id', data.sub_category_id);
		formData.append('document_id', data.document_id);
		formData.append('description', data.description);
		formData.append('part_name', data.part_name);
		formData.append('user_service_id', data.user_service_id);
		data.files_array.forEach(o => {
			formData.append('photos_array[]', o);
		});
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_service_id", data.user_service_id);
		return this.http.post<Object>(url, formData, {params});
	}
	
	public deleteDocumentFile(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document-item/${data.id}`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.delete<Object>(url, {params});
	}
	
	public deleteDocument(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("document_id", data.id);
		return this.http.delete<Object>(url, {params});
	}
	
	public changeUserPassword(data): Observable<any> {
		const url = `${this.env.apiBase}/user/change-password`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("old_password", data.previous_password)
		.append("new_password", data.password)
		.append("password_confirmation", data.password_confirmation);
		return this.http.put(url, {}, {
			params: params
		});
	}
	
	public downloadTaxCalcAndDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/pdf`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("language_code", data.language_code)
		.append("user_owner_id", data.user_id)
		.append("type", data.type)
		.append("period_id", (!data.period_id) ? "" : data.period_id);
		
		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}
	public downloadOfficialConfDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/pdf`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("language_code", data.language_code)
		.append("user_owner_id", data.user_id)
		.append("type", data.type)
		.append("period_id", (!data.period_id) ? "" : data.period_id);
		
		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}
	
	public getSubmittedDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "2")
		.append("with_pdf", "1")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	public getOfficialConfDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "6")
		.append("with_pdf", "1")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getSubmittedTaxCalculations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "3")
		.append("with_pdf", "1")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public postTaxCalcAndDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, data, {params});
	}
	public postOfficialConfDocs(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, data, {params});
	}
	
	public feedbackMessageSend(data): Observable<any> {
		const url = `${this.env.apiBase}/user/email-to-adviser`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("subject", data.subject)
		.append("message", data.message);
		return this.http.post<Object>(url, data["attachments"]["files_array"].length ? data["attachments"] : "", {params});
	}
	
	public getGmbhData(): Observable<any> {
		this.getUserData();
		const url = `${this.env.apiBase}/gmbh`;
		let params = new HttpParams()
		.append('userId', this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public changeUserData(data): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.put(url, {
			user_id: data.user_id,
			name: data.first_name,
			second_name: data.last_name,
			email: data.email,
			address: data.address,
			additional_information: data.additional_information
		}, {
			params: params
		});
	}
	
	public getSubmittedPOADocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "4")
		.append("with_pdf", "1")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getSubmittedTaxAdvisorAccountsDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "5")
		.append("with_pdf", "1")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public deleteUser(data): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append('user_id', data.id);
		return this.http.delete<Object>(url, {params});
	}
}
