<div class="container">
  <div class="row">
    <div class="main-content col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
      <h1 class="main_title">{{'User_data_protection.Page_main_title' | translate}}</h1>
      <h4 class="paragraph-title">{{'User_data_protection.First_paragraph_title' | translate}}</h4>
      <div class="paragraph">
        <p>{{'User_data_protection.First_paragraph_text_1' | translate}}</p>
        <p>{{'User_data_protection.First_paragraph_text_2' | translate}}</p>
      </div>
      <h4 class="paragraph-title">{{'User_data_protection.Second_paragraph_title' | translate}}</h4>
      <div class="paragraph">
        <p>{{'User_data_protection.Second_paragraph_text' | translate}}</p>
      </div>
      <h4 class="paragraph-title">{{'User_data_protection.Third_paragraph_title' | translate}}</h4>
      <div class="paragraph">
        <p>{{'User_data_protection.Third_paragraph_text' | translate}}</p>
      </div>
    </div>
  </div>
</div>
