import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(
    private router: Router
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const expected_roles = !route.data['role'] ? ['all'] : route.data['role'];
    if (user) {
      for (let i = 0; i <= expected_roles.length; i++) {
        if (user.role === expected_roles[i] || expected_roles[i] === 'all') {
          console.log('Logged');
          // logged and role is as expected so return true
          return true;
        } else {
          this.router.navigate(['login']);
          return true;
        }
      }
    }
    
    console.log('NOT LOGGED');
    // not logged in so redirect to login page with the return url
    this.router.navigate(['login']);
    return false;
  }
}
