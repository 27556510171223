import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService, AuthenticationService, SharedService } from "../_services";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import 'rxjs/add/operator/do';
import { TranslateService } from "@ngx-translate/core";

@Component({
	moduleId: module.id,
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
	lang = localStorage.getItem('lang');
	@ViewChild("phone") phone;
	loginForm: FormGroup;
	
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private _SharedService: SharedService,
		private alertService: AlertService,
		private fb: FormBuilder,
		private elem: ElementRef
	) {
		this.lang = this.lang === 'uk' ? 'ua' : this.lang;
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang = res === 'uk' ? 'ua' : res;
		});
	}
	
	ngOnInit() {
		this.loginForm = this.fb.group({
			myPhone: ['', [Validators.required]],
			password: ['', [Validators.minLength(6), Validators.required]]
		});
	}
	
	onchangeValidation(): void {
		this.loginForm.controls['myPhone'].setErrors(null);
		if (this.phone.selectedCountry === null && this.elem.nativeElement.querySelector('.phone_number').getAttribute('dialCode') === 'false') {
			this.loginForm.controls['myPhone'].setErrors({'wrong_number': true});
		}
		if (!this.loginForm.controls['myPhone'].value.length) {
			this.loginForm.controls['myPhone'].setErrors({'required': true});
		}
	}
	
	login() {
		let objToSent = {
			'phone_code': '+' + this.elem.nativeElement.querySelector('.phone_number').getAttribute('dialCode'),
			'phone_number': null,
			'password': this.loginForm.value.password
		};
		objToSent['phone_number'] = this.loginForm.value.myPhone.replace(objToSent.phone_code, "");
		this.authenticationService.login(objToSent, true);
	}
	
}
