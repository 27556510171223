import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { EnvService } from "./env.service";

@Injectable()
export class RegistrationService {
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }
  
  public registerMobileUser(data): Observable<any> {
    const url = `${this.env.apiBase}/user`;
    let headers = new HttpHeaders()
      .append('Authorization', this.env.appToken);
    return this.http.post<Object>(url, data, {headers});
  }
  
  public acceptConfirmationSMS(data): Observable<any> {
    const url = `${this.env.apiBase}/user/${data.user_id}/acceptsms`;
    let headers = new HttpHeaders()
      .append('Authorization', data.phone_token);
    return this.http.post<Object>(url, {
      sms_code: data.sms_code
    }, {headers});
  }
  
  public setupPassword(data): Observable<any> {
    const url = `${this.env.apiBase}/user/${data.user_id}/setpwd`;
    let headers = new HttpHeaders()
      .append('Authorization', data.password_token);
    return this.http.post<Object>(url, {
      password: data.password
    }, {headers});
  }
  
}
