import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService, SharedService } from "../../../_services";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router, NavigationEnd, Event } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
	@ViewChild('mobilemenu') mobilemenu: ElementRef;
	
	lang = localStorage.getItem('lang');
	user: any = JSON.parse(localStorage.getItem('currentUser'));
	subscription: Subscription;
	isSurveyPassed: boolean = false;
	isProfileRoute: boolean = false;
	
	constructor(
		private authenticationService: AuthenticationService,
		private translate: TranslateService,
		private _SharedService: SharedService,
		public router: Router,
		public route: ActivatedRoute,
		public el: ElementRef,
		public renderer: Renderer2
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd ) {
				console.log(event.url);
				this.toggleMobileMenu(true);
				if (event.url.includes('profile') || event.url === '/') {
					console.log('qqq1');
					this.isProfileRoute = true;
				} else {
					console.log('qqq2');
					this.isProfileRoute = false;
				}
				/*(event.url.includes('profile')) ? this.isProfileRoute = true : this.isProfileRoute = false;
				(event.url === '/') ? this.isProfileRoute = true : this.isProfileRoute = false;*/
			}
		});
		
		this.subscription = this._SharedService.serviceSurveyPassedEmit$.subscribe(
			res => {
				this.getUser();
				this.isSurveyPassed = false;
				if (res) {
					switch (res.current_service.id) {
						case 1:
							this.isSurveyPassed = false;
							break;
						case 2:
							if (res.posted_services.is_post_service_2) {
								this.isSurveyPassed = true;
							}
							break;
						case 3:
							if (res.posted_services.is_post_service_3) {
								this.isSurveyPassed = true;
							}
							break;
						case 4:
							if (res.posted_services.is_post_service_4) {
								this.isSurveyPassed = true;
							}
							break;
					}
				}
			}
		);
	}
	
	public getUser(): void {
		this.user = JSON.parse(localStorage.getItem('currentUser'));
	}
	
	
	ngOnInit() {
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang = res;
			this.translate.use(this.lang);
			localStorage.setItem('lang', this.lang);
		});
		this._SharedService.emitConnection
		.subscribe(() => {
			this.getUser();
		});
	}
	
	public logout(): void {
		this.authenticationService.logout();
	}
	
	public useLanguage(lang): void {
		this._SharedService.changeLanguageEvent.emit(lang);
	}
	
	public toggleMobileMenu(close?): void {
		let contentContainer = document.querySelectorAll('.top-panel');
		let paddingHeight;
		let hasClass = this.mobilemenu.nativeElement.classList.contains('open');
		if (close) {
			this.renderer.removeClass(this.mobilemenu.nativeElement, 'open');
			paddingHeight = this.mobilemenu.nativeElement.lastChild.offsetHeight + 30;
		} else {
			if (hasClass) {
				this.renderer.removeClass(this.mobilemenu.nativeElement, 'open');
				paddingHeight = this.mobilemenu.nativeElement.lastChild.offsetHeight + 30;
			} else {
				this.renderer.addClass(this.mobilemenu.nativeElement, 'open');
				paddingHeight = this.mobilemenu.nativeElement.lastChild.offsetHeight + 30;
			}
		}
		if (contentContainer.length) {
			this.renderer.setStyle(contentContainer[0], 'padding-top', `${paddingHeight}px`);
		}
	}
}
