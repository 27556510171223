<div class="login-tpl container content-container">
	<div class="row">
		<div class="main-content col-12">
			
			<div class="login-page-title">
				<p>{{ 'Front_page.Login_page_greetings1' | translate }}</p>
				<p>{{ 'Front_page.Login_page_greetings2' | translate }}</p>
			</div>
			
			<div class="login-form-wrapper col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
				<form name="loginForm" id="loginForm" class="loginForm col col-12" (ngSubmit)="loginForm.valid && login()" [formGroup]="loginForm" #f="ngForm" [class.ng-submitted-error]="f.submitted && f.form.invalid" novalidate>
					
					<div class="form-group required_section phone-number">
						<div class="input-wrapper">
							<label>
								{{ 'Header.Phone_number_label' | translate }}
								<span class="required_asterisk">*</span>
							</label>
							<international-phone-number #phone placeholder="" (ngModelChange)="onchangeValidation()" [defaultCountry]="'de'" formControlName="myPhone" [required]="false" [ngClass]="{ 'submitted' : f.submitted}"></international-phone-number>
						</div>
						<div class="form-control-feedback"
							 *ngIf="f.submitted && loginForm.controls['myPhone'].errors || loginForm.controls['myPhone'].errors && (loginForm.controls['myPhone'].dirty || loginForm.controls['myPhone'].touched)">
							<p *ngIf="loginForm.controls['myPhone'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
							<p *ngIf="loginForm.controls['myPhone'].errors && loginForm.controls['myPhone'].errors.wrong_number">{{'Shared.Phone_number_is_not_valid' | translate}}</p>
						</div>
					</div>
					
					<div class="form-group required_section password">
						<div class="input-wrapper">
							<label for="password">
								{{ 'Header.Password_label' | translate }}
								<span class="required_asterisk">*</span>
							</label>
							<input type="password" class="form-control" placeholder="{{ 'Shared.Enter_your_password_please' | translate }}" name="password" id="password" formControlName="password"/>
						</div>
						<div class="form-control-feedback"
							 *ngIf="f.submitted && loginForm.controls['password'].errors || loginForm.controls['password'].errors && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
							<p *ngIf="f.submitted && loginForm.controls['password'].errors.required || loginForm.controls['password'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
							<p *ngIf="f.submitted && loginForm.controls['password'].errors.minlength" translate [translateParams]="{ requiredLength: loginForm.controls['password'].errors.minlength.requiredLength }">
								Shared.Password_length_required
							</p>
						</div>
					</div>
					
					<div class="form-controls">
						<div class="controls-wrapper">
							<button class="main-action-btn form-submit-btn" type="submit">
								{{ 'Front_page.Log_in_btn' | translate }}
							</button>
						</div>
					</div>
				
				</form>
			</div>
			
			<div class="unregistered-users-tips">
				<p>
					<span>{{ 'Front_page.Unregistered_users_tips1' | translate }}</span>
					<span><a [href]="'https://proton-tax.crio-server.com/our-services-' + lang">{{ 'Front_page.Unregistered_users_tips_link' | translate }}</a></span>
					<span>{{ 'Front_page.Unregistered_users_tips2' | translate }}</span>
				</p>
			</div>
		</div>
	</div>
</div>
