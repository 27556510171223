import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
	HttpParameterCodec,
	HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/do';
import { AlertService, AuthenticationService, SharedService } from "../_services";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	
	constructor(
		private _AuthService: AuthenticationService,
		private _SharedService: SharedService,
		private _AlertService: AlertService,
		private router: Router,
		private route: ActivatedRoute
	) {
	}
	
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser && currentUser.api_key) {
			request = request.clone({
				setHeaders: {
					Authorization: currentUser.api_key
				}
			});
		}
		return next.handle(request)
			.do((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
				
				}
			}, (err: any) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 401 && !err.url.includes('user/login')) {
						this._AuthService.logout();
						this._AlertService.error('Alert.Auth_error');
					}
				}
			});
	}
}

@Injectable()
export class EncodeHttpParamsInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const params = new HttpParams({encoder: new CustomEncoder(), fromString: req.params.toString()});
		return next.handle(req.clone({params}));
	}
}


class CustomEncoder implements HttpParameterCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}
	
	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
	
	decodeKey(key: string): string {
		return decodeURIComponent(key);
	}
	
	decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}

