<div class="container">
	<div class="row">
		<!--<div class="main-content col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">-->
		<div class="main-content col-12" *ngIf="!device">
			<!--<app-unauth-survey *ngIf="!USER"></app-unauth-survey>-->
			<app-auth-survey
				*ngIf="newService && newService == 1 || !newService && USER.current_service.id === 1"></app-auth-survey>
			<app-gmbh-form
				*ngIf="(newService && newService == 2 || !newService && USER.current_service.id === 2) && !USER.posted_services.is_post_service_2"></app-gmbh-form>
			<app-taxid-form
				*ngIf="(newService && newService == 3 || !newService && USER.current_service.id === 3) && !USER.posted_services.is_post_service_3"></app-taxid-form>
			<app-taxid-buildtax-form
				*ngIf="(newService && newService == 4 || !newService && USER.current_service.id === 4) && !USER.posted_services.is_post_service_4"></app-taxid-buildtax-form>
		</div>
	</div>
	<div class="main-content col-12" *ngIf="device" [ngClass]="{'device': device}">
		<!--<app-unauth-survey *ngIf="!USER"></app-unauth-survey>-->
		<app-auth-survey
			*ngIf="newService && newService == 1 || !newService && USER.current_service.id === 1"></app-auth-survey>
		<app-gmbh-form
			*ngIf="(newService && newService == 2 || !newService && USER.current_service.id === 2)"></app-gmbh-form>
		<app-taxid-form
			*ngIf="(newService && newService == 3 || !newService && USER.current_service.id === 3)"></app-taxid-form>
		<app-taxid-buildtax-form
			*ngIf="(newService && newService == 4 || !newService && USER.current_service.id === 4)"></app-taxid-buildtax-form>
	</div>
</div>