import { Component, OnInit } from '@angular/core';
import {AuthenticationService, SharedService} from "../_services/index";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-user-data-protection',
  templateUrl: './user-data-protection.component.html',
  styleUrls: ['./user-data-protection.component.less']
})
export class UserDataProtectionComponent implements OnInit {

  subscription: Subscription;
  lang: any = {
    active: localStorage.getItem('lang')
  };
  singleMobileUser;

  constructor(
  ) {

  }

  ngOnInit() {

  }

}
