import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import 'rxjs/add/operator/map';
// import { environment } from "../../environments/environment";
import { EnvService } from "./env.service";
import { AlertService } from "./alert.service";
import { SharedService } from "./shared.service";
import { UserService } from "./user.service";

@Injectable()
export class AuthenticationService {
	
	constructor(
		private env: EnvService,
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private _SharedService: SharedService,
		private _AlertService: AlertService,
		private _UserService: UserService
	) {
	}
	
	login(data, fromComponent?): any {
		let objToSent = {};
		if (fromComponent) {
			localStorage.removeItem('surveyDataV2');
		}
		if (data.last_question_id && !fromComponent) {
			objToSent = {
				last_question_id: data.last_question_id,
				last_answer_id: data.last_answer_id,
				last_period_id: data.last_period_id,
				next_question_id: data.last_question_id,
				attributes: data.attributes
			};
		}
		const url = `${this.env.apiBase}/user/login`;
		let params = new HttpParams()
		.append('phone_code', data.phone_code)
		.append('phone_number', data.phone_number)
		.append('password', data.password);
		return this.http.post<Object>(url, objToSent, {params})
		.subscribe(res => {
			if (res['user'] && res['user'].api_key) {
				localStorage.setItem('currentUser', JSON.stringify(res['user']));
				this._AlertService.success('Alert.Successful_login');
				if (this.route.snapshot.queryParams['passed']) {
					this.router.navigate(['/survey'], {
						queryParams: {
							passed: 'true'
						}
					});
				} else {
					this.router.navigate(['']);
				}
				
				if (!res['user'].current_service) {
					this._UserService.addServiceForUser({
						id: (res['user'].service === 0) ? 1 : res['user'].service,
						user_id: res['user'].id
					}).subscribe(response => {
						let currentService = response.services.filter((obj) => {
							return obj.is_current
						});
						res['user'].current_service = currentService[0];
						localStorage.setItem('currentUser', JSON.stringify(res['user']));
					}, error => {
						console.log(error);
					})
					
				}
				
				this._SharedService.emitConnection.emit();
			}
		}, err => {
			console.log(err);
			this._AlertService.error('Alert.Wrong_phone_or_password');
		});
	}
	
	logout(): void {
		// remove user from local storage to log user out
		for (let i = localStorage.length; i--;) {
			let key = localStorage.key(i);
			if (key !== 'lang' && key !== 'dataProtection') {
				localStorage.removeItem(key);
			}
		}
		this.router.navigate(['login']);
		this._SharedService.emitConnection.emit();
	}
	
}
